import React from 'react';
import {useAuth} from "../contexts/AuthContext";
import {Navigate} from 'react-router-dom'
import  secureLocalStorage  from  "react-secure-storage";

import LogIn from "./authentification/LogIn";
function PrivateRoute ({ component: Component, ...rest}) {

        try{
            const uid = useAuth().currentUser.uid
            if (uid)
                console.log(uid)
        }
        catch (e){
            secureLocalStorage.setItem("logged-in", false)
        }

        if (!secureLocalStorage.getItem("logged-in"))
            return (
                <Navigate redirect to="/log-in"/>
            )
        else
            return <Component/>


}

export default PrivateRoute;