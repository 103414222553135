import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';

const chartSetting = {
    yAxis: [
        {
            label: ''
        }
    ],
    width: 600,
    height: 400,
    sx: {
        [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: "rotate(-90deg) translate(0px, -10px)"
        }
    }
};
const valueFormatter = (value) => `${value} RON`;

export default function VerticalChart({mechanics, selectedDates, statisticsData}) {

    let mechanicsSeries = []
    mechanics.forEach(mechanic => mechanicsSeries.push({dataKey: mechanic.id, label: (mechanic.name.includes(' ') ? mechanic.name.split(' ')[0] +  ' ' + mechanic.name.split(' ')[1][0]+ mechanic.name.split(' ')[1][1] : mechanic.name), valueFormatter}))

    const calculateTotal = (id) => {
        let total = 0
        selectedDates.forEach(date => {
            if (statisticsData[date])
                total += statisticsData[date][id]
        })
        return total
    }

    let dataSet = {total: 'Total lucrari'}
    mechanics.forEach(mechanic => dataSet[mechanic.id] = calculateTotal(mechanic.id))

    const dataset = [dataSet]
    // console.log(dataset)
    // console.log(mechanicsSeries)

    return (
        <BarChart
            dataset={dataset}
            xAxis={[{ scaleType: 'band', dataKey: 'total'}]}
            series={mechanicsSeries}
            {...chartSetting}
        />
    );
}