import React, {useEffect} from 'react';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.min.css';
import {useState} from 'react'
import { startOfDay, endOfDay, addDays, subDays } from 'date-fns';
import VerticalChart from "./VerticalChart";
import LChart from "./LChart";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid'
import TableStatistics from "./TableStatistics";

const styles = { width: 300, display: 'block', marginBottom: 10 };
const Ranges = [
    {
        label: 'Astăzi',
        value: [startOfDay(new Date()), endOfDay(new Date())]
    },
    {
        label: 'Ieri',
        value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))]
    },
    {
        label: 'Ultima săptămână',
        value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())]
    },

    {
        label: 'Ultima lună',
        value: [startOfDay(subDays(new Date(), 31)), endOfDay(new Date())]
    },

    {
        label: 'Ultimul an',
        value: [startOfDay(subDays(new Date(), 365)), endOfDay(new Date())]
    }

];

function MehanicsCheckboxList({mechanics, selectedMechanics, setSelectedMechanics}) {
        const [checked, setChecked] = React.useState([1]);

        const handleToggle = (value) => () => {

            const currentIndex = checked.indexOf(value);
            const newChecked = [...checked];

            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }

            setChecked(newChecked);

            let newSelectedMechanics = {...selectedMechanics}
            newSelectedMechanics[value.id] = !newSelectedMechanics[value.id]
            setSelectedMechanics(newSelectedMechanics)
        };

        return (
            <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {mechanics.map((value) => {
                    const labelId = `checkbox-list-secondary-label-${value}`;
                    return (
                        <ListItem
                            key={value.id}
                            secondaryAction={
                                <Checkbox
                                    edge="end"
                                    onChange={handleToggle(value)}
                                    checked={checked.indexOf(value) !== -1}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            }
                            disablePadding
                        >
                            <ListItemButton>
                                <ListItemAvatar>
                                    <Avatar
                                        alt={''}
                                        src={value.imageURL}
                                    />
                                </ListItemAvatar>
                                <ListItemText id={labelId} primary={value.name} />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        );
}

function checkIfDateInInterval (startDate, date, endDate) {
    //TODO
}

function generateDateRange(startDate, endDate) {
    const dateArray = [];
    if (startDate === '' || endDate === '')
        return []
    const [startDay, startMonth, startYear] = startDate.split('.').map(Number);
    const [endDay, endMonth, endYear] = endDate.split('.').map(Number);

    let currentDate = new Date(startYear, startMonth - 1, startDay); // Month is 0-based in JavaScript Date

    const endDateObj = new Date(endYear, endMonth - 1, endDay);

    while (currentDate <= endDateObj) {
        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = currentDate.getFullYear();
        const formattedDate = `${day}.${month}.${year}`;
        // const formattedDate = `${month}.${day}.${year}`;
        dateArray.push(formattedDate);
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray;
}

function Statistics({mechanics, statisticsData}) {
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')


    useEffect(() => {
        let newSelectedMechanics = {}
        mechanics.map(mechanic => newSelectedMechanics[mechanic.id] = false)
        setSelectedMechanics(newSelectedMechanics)
    }, [])

    const [selectedMechanics, setSelectedMechanics] = useState({})
    const [selectedDates, setSelectedDates] = useState([])
    useEffect(() => {
        setSelectedDates(generateDateRange(startDate, endDate))
    }, [startDate, endDate])



    return (
        <div>
            <Grid container width={'auto'} paddingLeft={'1vw'}>
                <Grid item>
                    <DateRangePicker size="lg" placeholder="Selectează un interval" style={styles} format = {'dd-MM-yyyy'} character={' până la '}  onChange={(e) => {


                        const day = e[0].getDate().toString().padStart(2, '0');
                        const month = (e[0].getMonth() + 1).toString().padStart(2, '0');
                        const year = e[0].getFullYear();

                        let start = `${day}.${month}.${year}`;
                        start = start.replaceAll('/', ".")
                        setStartDate(start)


                        const dayEnd = e[1].getDate().toString().padStart(2, '0');
                        const monthEnd = (e[1].getMonth() + 1).toString().padStart(2, '0');
                        const yearEnd = e[1].getFullYear();

                        let end = `${dayEnd}.${monthEnd}.${yearEnd}`;
                        end = end.replaceAll('/', ".")
                        setEndDate(end)
                    }}
                                     ranges={Ranges}

                    />
                    <MehanicsCheckboxList mechanics={mechanics} selectedMechanics={selectedMechanics} setSelectedMechanics={setSelectedMechanics}/>
                </Grid>

                <Grid item>
                    <VerticalChart mechanics={mechanics.filter(mechanic => selectedMechanics[mechanic.id])} selectedDates={selectedDates} statisticsData = {statisticsData}/>
                </Grid>

                <Grid item>
                    <LChart mechanics={mechanics.filter(mechanic => selectedMechanics[mechanic.id])} selectedDates={selectedDates} statisticsData = {statisticsData}/>
                </Grid>

                <Grid item>
                    <TableStatistics mechanics={mechanics.filter(mechanic => selectedMechanics[mechanic.id])} selectedDates={selectedDates} statisticsData = {statisticsData}/>
                </Grid>


            </Grid>


        </div>
    );
}

export default Statistics;