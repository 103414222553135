import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button'
import Grid from "@mui/material/Grid";
import {useState} from "react";
import Typography from "@mui/material/Typography";
import {useAuth} from "../../contexts/AuthContext";
import {useEffect} from "react";
import {doc, getDoc, setDoc} from "@firebase/firestore";
import {db} from "../authentification/firebase";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";

export default function AddServiceRequirement ({problemsData, setProblemsData, addProblemLabel, helperText, problemsDict, setProblemsDict,
                                                   hourlyLabourData, setHourlyLabourData,
                                                   servicesData, setServicesData}) {

    const handleSaveRequirements = async () => {
        requirementsList &&
        requirementsList.length !== 0 &&
        await setDoc(doc(db, `${uid}lists`, 'requirements'), {
            requirementsList,
            toggleRequirementsAutomaticAdd
        })
    }
    const [toggleRequirementsAutomaticAdd, setToggleRequirementsAutomaticAdd] = useState(false);
    const [requirementsList, setRequirementsList] = useState()

    const [options, setOptions] = useState([])
    const [price, setPrice] = useState()

    const currentUser = useAuth().currentUser
    const uid = currentUser.uid

    useEffect(() => {
        let docRef = null
        let docSnap = null

        const getOptions = async () => {
            try{
                docRef = await doc(db,  `${uid}lists`, 'hourlyLabours')
                docSnap = await getDoc(docRef);
            }
            catch(e){
            }
        }

        getOptions().then(() => {
            try {
                let data = docSnap.data()
                setOptions(data.hourlyLaboursList)
                setPrice(data.hourlyLabourPrice)
            }
            catch(e) {

            }

            }
        )
    }, [])

    useEffect(() => {
        let docRef = null
        let docSnap = null

        const getRequirementsData = async () => {
            try{
                docRef = await doc(db,  `${uid}lists`, 'requirements')
                docSnap = await getDoc(docRef);
            }
            catch(e){
            }
        }

        getRequirementsData().then(() => {
            try{
                let data = docSnap.data()
                setRequirementsList(data.requirementsList)
                setToggleRequirementsAutomaticAdd(data.toggleRequirementsAutomaticAdd)
            }

            catch (e) {

            }

            }
        )
    }, [])

    useEffect(() => {
        if (requirementsList && requirementsList.length !== 0)
            handleSaveRequirements()
    }, [requirementsList, toggleRequirementsAutomaticAdd])


    const addNewRequirementToOptionsList = () => {
        const newRequirementsList = []
        let add = true

        if (requirementsList && requirementsList.length !== 0){
            requirementsList.forEach((element) => {
                if (element.item === problemsData.newProblem)
                    add = false;
                newRequirementsList.push({
                    item: element.item,
                })
            })
        }
        newRequirementsList.push({
            item: problemsData.newProblem,
        })
        if (add && toggleRequirementsAutomaticAdd) {
            setRequirementsList(newRequirementsList)
        }
    }

    const handleAddNewProblem = () => {
        if (problemsData.newProblem === '')
            ;
        else {
            const problems = problemsData.problems.slice(0)
            var adauga = true;
            for (var i=0; i<problems.length; i++)
                if (problems[i] === problemsData.newProblem)
                    adauga = false
            if (adauga) {
                problems.push({problem: problemsData.newProblem, addLabourType: 'hourlyLabour' })
                problemsDict.push({
                        key: problemsData.newProblem,
                        value: {
                            problems: [],
                            newProblem: ''
                        }
                    }

                )
            }
            setProblemsData({...problemsData, problems: problems, newProblem: ''})
            hourlyLabourData.items.push({item: problemsData.newProblem, quantity: 1, price: price, total: price})
        }

    }
    return (
        <Grid container spacing={3} direction={'row'} justifyContent={'space-between'} alignItems={'center'} width = 'auto' marginBottom={'3vh'}>
            {/*<Grid item>*/}
            {/*    <Typography fontSize={'140%'}>*/}
            {/*        {helperText}*/}
            {/*    </Typography>*/}
            {/*</Grid>*/}
            <Grid item>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={toggleRequirementsAutomaticAdd}
                                onChange={() => {
                                    setToggleRequirementsAutomaticAdd(!toggleRequirementsAutomaticAdd)
                                }}
                            />
                        }
                        label={'Adauga cerinte automat'}
                    />
                </FormGroup>
            </Grid>
            <Grid item>
                <Autocomplete
                    size={'small'}
                    freeSolo
                    options={requirementsList ? requirementsList.map( e => e.item) : []}
                    sx={{width: 300}}
                    value = {problemsData.newProblem}
                    renderInput={(params) => <TextField{...params} label={addProblemLabel} onChange={
                        (event) => setProblemsData({
                            ...problemsData,
                            newProblem: event.target.value
                        })}
                                                       onSelect = {
                                                           (event) => setProblemsData({
                                                               ...problemsData,
                                                               newProblem: event.target.value
                                                           })}

                    />}
                />

            </Grid>

            <Grid item>

                <Button variant="outlined"
                        onClick={() => {
                            handleAddNewProblem()
                            addNewRequirementToOptionsList()
                        }}
                >
                    Adaugă</Button>

            </Grid>
        </Grid>
    );
}