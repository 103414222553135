import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';

export default function LChart({mechanics, selectedDates, statisticsData}) {

    const mechanicsChartData = []
    mechanics.forEach(mechanic => {

        let mechanicPerformanceData = []

        selectedDates.forEach(date => {
            if (statisticsData[date])
                mechanicPerformanceData.push(statisticsData[date][mechanic.id])
            else mechanicPerformanceData.push(0)
        })

        mechanicsChartData.push({
            data: mechanicPerformanceData,
            label: (mechanic.name.includes(' ') ? mechanic.name.split(' ')[0] +  ' ' + mechanic.name.split(' ')[1][0]+ mechanic.name.split(' ')[1][1] : mechanic.name),
            labelKey: mechanic.id,
            yAxisKey: 'leftAxisId',
            curve: 'linear'
        })
    })
    return (
        <LineChart
            width={600}
            height={400}
            series={mechanicsChartData}
            xAxis={[{ scaleType: 'point', data: selectedDates }]}
            yAxis={[{ id: 'leftAxisId' }]}
        />
    );
}