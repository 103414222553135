import React from 'react';
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {FormControlLabel, FormGroup, Tab, Tabs} from "@mui/material";
import {useState} from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Card from '@mui/material/Card';
import SearchIcon from "@mui/icons-material/Search";
import {useEffect} from 'react'
import {useAuth} from "../../../contexts/AuthContext";
import {ref} from "firebase/storage";
import {db, storage} from "../../authentification/firebase";
import {doc, getDoc, setDoc} from "@firebase/firestore";
import {Checkbox} from "@mui/material";

function OfferedServices(props) {


    const handlePartDelete = (element) => {
        const newPartsList = partsList.filter((e) => e.item !== element.item)
        setPartsList(newPartsList);
    }

    const currentUser = useAuth().currentUser
    const uid = currentUser.uid


    const [newPart, setNewPart] = useState('')
    const [partsSearchValue, setPartsSearchValue] = useState('')
    const [partsList, setPartsList] = useState([]);
    const [togglePartsAutomaticAdd, setTogglePartsAutomaticAdd] = useState(false);

    const addNewPart = () => {
        if (newPart === '')
            return
        const newPartsList = []
        let add = true
        partsList && partsList.forEach((element) => {
            if (element.item === newPart)
                add = false;
            newPartsList.push({
                item: element.item,
            })
        })

        newPartsList.push({
            item: newPart,
        })
        if (add) {
            setPartsList(newPartsList)
        }
        setNewPart('')
    }

    const handleSaveParts = async () => {
        await setDoc(doc(db, `${uid}lists`, 'parts'), {
            partsList,
            togglePartsAutomaticAdd
        })
    }

    useEffect(() => {
        let docRef = null
        let docSnap = null

        const getPartsData = async () => {
            try{
                docRef = await doc(db,  `${uid}lists`, 'parts')
                docSnap = await getDoc(docRef);
            }
            catch(e){
            }
        }

        getPartsData().then(() => {
            try{
                let data = docSnap.data()
                setPartsList(data.partsList)
                setTogglePartsAutomaticAdd(data.togglePartsAutomaticAdd)
            }

            catch (e) {

            }

        })

    }, [])
    useEffect(() => {
        if (partsList && partsList.length !== 0)
            handleSaveParts()
    }, [partsList, togglePartsAutomaticAdd])


    return (
        <div>
            <Grid container spacing={2}
                  direction="column"
                  justifyContent="space-around  "
                  alignItems="center"
                  auto
                  padding={5}
                  style={{gap: 30}}
            >

                    <>
                        <Grid container spacing={3} direction={'row'} justifyContent={'space-between'} alignItems={'center'} width = 'auto' marginBottom={'3vh'}>


                            <Grid item >
                                <TextField  size={'small'}
                                            label={'Denumire piesa'}
                                            onChange={(event)=>{
                                                setNewPart(event.target.value)
                                            }}
                                            value={newPart}
                                />
                            </Grid>

                            <Grid item>
                                <Button variant={'outlined'}
                                        onClick={addNewPart}
                                > Adaugă </Button>
                            </Grid>
                            <Grid item>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={togglePartsAutomaticAdd}
                                                onChange={() => {
                                                    setTogglePartsAutomaticAdd(!togglePartsAutomaticAdd)
                                                }}
                                            />
                                        }
                                        label={'Salveaza piesele adaugate automat'}
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Card sx={{ width: '100%', maxWidth: '40vw', minHeight: '60vh', bgcolor: 'background.paper' }} elevation={6}>
                            <Box sx={{ width: '80%', bgcolor: 'background.paper', paddingLeft: '25%', paddingTop: '30px'}}>

                                <TextField size={'small'}
                                           label={'Caută o piesa'}
                                           onChange={(event)=>{
                                               setPartsSearchValue(event.target.value)
                                           }}
                                />

                                <IconButton disabled={true}>
                                    <SearchIcon color={'primary'}
                                    />
                                </IconButton>
                            </Box>
                            <Box sx={{ width: '80%', maxWidth: '20vw', maxHeight: '50vh', overflow: 'auto', bgcolor: 'background.paper', paddingLeft: '25%', paddingTop: '30px', marginBottom: '30px'}}>


                                <List style={{maxHeight: '100%', overflow: 'auto'}}>
                                    {partsList && partsList.map(partsItem => {
                                        if (partsItem.item.toLowerCase().includes(partsSearchValue.toLowerCase()))
                                        {
                                            return <ListItem sx={{
                                                borderStyle: 'solid',
                                                borderWidth: '1px',
                                                borderColor: 'gray',
                                                marginBottom: '1px',
                                                borderRadius: '5px',
                                                '&:nth-of-type(even)': {
                                                    backgroundColor: "whitesmoke",
                                                },
                                            }}>
                                                <ListItemText primary= {partsItem.item} />
                                                <IconButton onClick={() => {
                                                    handlePartDelete(partsItem)
                                                }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItem>
                                        }
                                    })}
                                </List>
                            </Box>
                        </Card>
                    </>

            </Grid>

        </div>
    );
}

export default OfferedServices;