import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import Card from "@mui/material/Card";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import Box from "@mui/material/Box";

export default function TableStatistics({mechanics, selectedDates, statisticsData}) {


    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'mechanic',
            headerName: 'Mecanic',
            width: 150,
            editable: false,
        },
        {
            field: 'date',
            headerName: 'Data',
            width: 150,
            editable: false,
        },

        {
            field: 'performance',
            headerName: 'Manopera',
            width: 110,
            editable: true,
        },
        // {
        //     field: 'fullName',
        //     headerName: 'Full name',
        //     description: 'This column has a value getter and is not sortable.',
        //     sortable: false,
        //     width: 160,
        //     valueGetter: (params) =>
        //         `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        // },
    ];

    const rows = []

    const mechanicsChartData = []
    mechanics.forEach(mechanic => {

        let mechanicPerformanceData = []

        selectedDates.forEach(date => {
            if (statisticsData[date])
                mechanicPerformanceData.push(statisticsData[date][mechanic.id])
            else mechanicPerformanceData.push(0)
        })

        mechanicsChartData.push({
            data: mechanicPerformanceData,
            label: (mechanic.name.includes(' ') ? mechanic.name.split(' ')[0] +  ' ' + mechanic.name.split(' ')[1][0]+ mechanic.name.split(' ')[1][1] : mechanic.name),
            labelKey: mechanic.id,
            yAxisKey: 'leftAxisId',
            curve: 'linear'
        })
    })
    let index = 1
    for (const [keyMechanic, valueMechanic] of Object.entries(mechanics))
        selectedDates.forEach(currentDate => {
            rows.push({
                id: index,
                date: currentDate,
                mechanic: valueMechanic.name,
                performance: statisticsData[currentDate] ? statisticsData[currentDate][valueMechanic.id] : 0,
            })
            index++
        })





    return (

            <Box sx={{ height: 400, width: 600 }}>
                <DataGrid
                    slots={{ toolbar: GridToolbar }}
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 20,
                            },
                        },
                    }}
                    pageSizeOptions={[20]}
                    checkboxSelection
                    disableRowSelectionOnClick
                />
            </Box>

    );
}