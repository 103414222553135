import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import {useEffect} from "react";

export default function ReceptionInfoList({receptionData, setReceptionData}) {
    const handleReceptionInfoDelete = (value) => {
        var receptionInfo = receptionData.info.slice(0);
        const index = receptionInfo.indexOf(value)
        receptionInfo.splice(index, 1);

        setReceptionData({
            ...receptionInfo,
            info: receptionInfo
        }, [])
    }

    return (
        <List sx={{ width: '100%',
            maxWidth: 360,
            bgcolor: 'background.paper' ,
            alignItems: 'flex-start' ,
            paddingLeft: '20px',
            marginTop: '3vh'
        }}>
            {receptionData.info.map((value) => (
                <ListItem
                    key={value}
                    sx={{
                        borderRadius: '5px',
                        width: '40vw',
                        paddingLeft: '10px',
                        minHeight: '60px',
                        transition: "transform 0.15s ease-in-out",
                        "&:hover": {
                            backgroundColor: 'background.paper',
                            color: "black",
                            border: '1px',
                            borderColor: 'gray',
                            borderStyle: 'solid',
                            "& .MuiListItemIcon-root": {
                                color: "black",

                            },
                            transform: "scale3d(1.02, 1.02, 1.5)"
                        }
                        ,
                    }}
                >
                    <ListItemText primary={`${value}`} sx={{overflowWrap: 'break-word'}}/>
                    <IconButton sx={{
                        transition: "transform 0.15s ease-in-out",
                        "&:hover": {
                            backgroundColor: 'background.paper',
                            color: "red",
                            "& .MuiListItemIcon-root": {
                                color: "red"
                            },
                            transform: "scale3d(1.22, 1.22, 1.5)"
                        }
                    }}>                        <DeleteIcon onClick={() => {handleReceptionInfoDelete(value)}}>
                        </DeleteIcon>
                    </IconButton>


                </ListItem>
            ))}
        </List>
    );
}