import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button'
import Grid from "@mui/material/Grid";
import {useState, useEffect} from "react";
import Typography from "@mui/material/Typography";
import {doc, getDoc, setDoc} from "@firebase/firestore";
import {db} from "../authentification/firebase";
import {useAuth} from "../../contexts/AuthContext";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";

function AddPart({partsData, setPartsData}) {

    const [togglePartsAutomaticAdd, setTogglePartsAutomaticAdd] = useState(false);
    const [partsList, setPartsList] = useState()

    const currentUser = useAuth().currentUser
    const uid = currentUser.uid

    const handleSaveParts = async () => {
        partsList &&
        partsList.length !== 0 &&
        await setDoc(doc(db, `${uid}lists`, 'parts'), {
            partsList,
            togglePartsAutomaticAdd
        })
    }

    const handleAddNewPart = () => {
        if (partsData.newPart === '')
            ;
        else {
            const parts = partsData.parts.slice(0)
            var adauga = true;
            for (var i=0; i<parts.length; i++)
                if (parts[i] === partsData.newPart)
                    adauga = false
            if (adauga){
                const newPart = {
                    part: partsData.newPart,
                    quantity: 1,
                    price: 0,
                    total: 0,
                    partCode: '',
                    partManufacturer: '',
                    partInfo: '',
                    client: false
                }
                parts.unshift(newPart)

            }
            setPartsData({...partsData, parts: parts, newPart: ''})

        }

    }

    useEffect(() => {
        let docRef = null
        let docSnap = null

        const getPartsData = async () => {
            try{
                docRef = await doc(db,  `${uid}lists`, 'parts')
                docSnap = await getDoc(docRef);
            }
            catch(e){
            }
        }

        getPartsData().then(() => {
                try{
                    let data = docSnap.data()
                    setPartsList(data.partsList)
                    setTogglePartsAutomaticAdd(data.togglePartsAutomaticAdd)
                }

                catch (e) {

                }

            }
        )
    }, [])

    useEffect(() => {
        if (partsList && partsList.length !== 0)
            handleSaveParts()
    }, [partsList, togglePartsAutomaticAdd])

    const addNewPartToOptionsList = () => {
        const newPartsList = []
        let add = true

        if (partsList && partsList.length !== 0){
            partsList.forEach((element) => {
                if (element.item === partsData.newPart)
                    add = false;
                newPartsList.push({
                    item: element.item,
                })
            })
        }
        newPartsList.push({
            item: partsData.newPart,
        })
        if (add && togglePartsAutomaticAdd) {
            setPartsList(newPartsList)
        }
    }

    return (
        <Grid container spacing={3} direction={'row'} justifyContent={'space-between'} alignItems={'center'} width = 'auto' marginBottom={'3vh'}>
            {/*<Grid item >*/}
            {/*    <Typography variant="h6" gutterBottom>*/}
            {/*        Adauga piese in deviz:*/}
            {/*    </Typography>*/}
            {/*</Grid>*/}
            <Grid item>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={togglePartsAutomaticAdd}
                                onChange={() => {
                                    setTogglePartsAutomaticAdd(!togglePartsAutomaticAdd)
                                }}
                            />
                        }
                        label={'Salveaza piesele adaugate automat'}
                    />
                </FormGroup>
            </Grid>
            <Grid item>
                <Autocomplete
                    size={'small'}
                    freeSolo
                    id="combo-box-demo"
                    options={partsList ? partsList.map(e => e.item) : []}
                    sx={{width: 300}}
                    value = {partsData.newPart}
                    renderInput={(params) => <TextField{...params} label="Piesa" onChange={
                        (event) => setPartsData({
                            ...partsData,
                            newPart: event.target.value
                        })}
                                                       onSelect = {
                                                           (event) => setPartsData({
                                                               ...partsData,
                                                               newPart: event.target.value
                                                           })}
                    />}
                />

            </Grid>

            <Grid item>

                <Button variant="outlined"
                        onClick={() => {
                            handleAddNewPart()
                            addNewPartToOptionsList()
                        }}
                >

                    Adaugă</Button>

            </Grid>
        </Grid>
    );
}


export default AddPart