import React, {useRef} from "react";
import { Page, Text, Image, Document, StyleSheet, PDFViewer} from "@react-pdf/renderer";
import PartsList from "../estimateCreationForm/PartsList";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import HourlyLabourList from "../estimateCreationForm/HourlyLabourList";
import ServicesList from "../estimateCreationForm/ServicesList";
import ItemsList from "../estimateCreationForm/ItemsList";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { DataGrid } from '@mui/x-data-grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 24,
        textAlign: "center",
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: "justify",
        fontFamily: "Times-Roman",
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: "center",
        color: "grey",
    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },
});

export default function EstimatePreview({partsData, carData, problemsData, foundProblemsData, receptionData, problemsDict,
                                    hourlyLabourData, servicesData, finalVerificationsData,
                                    labourDiscount, labourDiscountType, partsDiscount, partsDiscountType,
                                    recommendationsData, previewDocumentRef, clientData,
                                    date,
                                    usersData, URL, mechanic,
                                    supervisor, finalVerificationsMechanic
                                            }) {

    const calculateTotalNoDiscountParts = () => {
        let sum = 0
        for (let i=0; i<partsData.parts.length; i++)
            sum += parseFloat(partsData.parts[i].total)
        return sum.toFixed(2)
    }

    const calculateDiscountParts = () => {
        let sum = calculateTotalNoDiscountParts()
        if (partsDiscountType === 'ammount')
            return parseFloat(partsDiscount).toFixed(2)
        else return (sum*(partsDiscount)/100).toFixed(2)
    }

    const calculateTotalParts = () => {
        return (calculateTotalNoDiscountParts() - calculateDiscountParts()).toFixed(2)
    }

    const calculateTotalHourlyLabour = () => {
        let sum = 0
        for (let i=0; i<hourlyLabourData.items.length; i++)
            sum += parseFloat(hourlyLabourData.items[i].total)
        return sum.toFixed(2)
    }

    const calculateTotalServices = () => {
        let sum = 0
        for (let i=0; i<servicesData.items.length; i++)
            sum += parseFloat(servicesData.items[i].total)
        return sum.toFixed(2)
    }

    const calculateTotalFinalVerifications = () => {
        let sum = 0
        for (let i=0; i<finalVerificationsData.items.length; i++)
            sum += parseFloat(finalVerificationsData.items[i].price)
        return sum.toFixed(2)
    }

    const calculateTotalNoDiscountLabours= () => {
        return (parseFloat(calculateTotalFinalVerifications()) + parseFloat(calculateTotalServices()) + parseFloat(calculateTotalHourlyLabour())).toFixed(2)
    }

    const calculateDiscountLabours = () => {
        let sum = calculateTotalNoDiscountLabours()
        if (labourDiscountType === 'ammount')
            return (parseFloat(labourDiscount).toFixed(2))
        else return (sum*(labourDiscount)/100).toFixed(2)
    }

   const calculateTotalLabours = () => {
        return (calculateTotalNoDiscountLabours() - calculateDiscountLabours()).toFixed(2)
    }

    const calculateTotal = () => {
        return (parseFloat(calculateTotalLabours()) + parseFloat(calculateTotalParts())).toFixed(2)
    }



    let problemsDescribedByClient = ''
    problemsData.problems.map((element) => (
        problemsDescribedByClient=problemsDescribedByClient.concat(element, '; ')
    ))
    problemsDescribedByClient=problemsDescribedByClient.slice(0,-2)


    let problemsFoundByService = ''
    foundProblemsData.problems.map((element) => (
        problemsFoundByService=problemsFoundByService.concat(element.problem, '; ')
    ))
    problemsFoundByService=problemsFoundByService.slice(0,-2)

    let receptionInfo = ''
    receptionData.info.map((element) => (
        receptionInfo=receptionInfo.concat(element, '; ')
    ))
    receptionInfo=receptionInfo.slice(0,-2)

    let req = []
    problemsDict.map(element => {
        req = req.concat(element.value.problems)
    })
    req = [...new Set(req)];

    let requirements = ""
    req.map((element) => (
        requirements=requirements.concat(element, '; ')
    ))
    requirements=requirements.slice(0,-2)


    let recommendations = ''
    recommendationsData.recommendations.map((element) => (
        recommendations=recommendations.concat(element, '; ')
    ))
    recommendations = recommendations.slice(0,-2)


    const rowsParts = []
    partsData.parts.forEach((part) => {
        rowsParts.push({
                part: part.part,
                price: part.price,
                quantity: part.quantity,
                total: part.total
            })
    })


    const rowsHourlyLabours = []
    hourlyLabourData.items.forEach((item) => {
        rowsHourlyLabours.push({
            item: item.item,
            price: item.price,
            quantity: item.quantity,
            total: item.total
        })
    })

    const rowsServices = []
    servicesData.items.forEach((item) => {
        rowsServices.push({
            item: item.item,
            price: item.price,
            quantity: item.quantity,
            total: item.total
        })
    })

    const rowsFinalVerifications = []
    finalVerificationsData.items.forEach((item) => {
        rowsFinalVerifications.push({
            item: item.itemName,
            price: item.price,
            corresponds: item.corresponds
        })
    })




    const pdfRef = useRef();

        return (
                <div ref={previewDocumentRef} style={{width: '1500px'}}>

                            {/*car information*/}


                                <Grid container
                                      direction="row"
                                      justifyContent="space-between"
                                      alignItems="flex-start"
                                      columns={21}
                                      width={'100%'}
                                      padding={5}
                                >
                                    <Grid item xs={7}>{usersData && <div>

                                        <Typography fontSize={'h4.fontSize'} fontWeight={630}>{usersData.company}</Typography>
                                        <Typography fontSize={'h5.fontSize'}>Sediu social: {usersData.address}</Typography>
                                        <Typography fontSize={'h5.fontSize'}>Punct de lucru: {usersData.workAddress}</Typography>
                                        <Typography fontSize={'h5.fontSize'}>Reg. Com.: {usersData.comerceRegisterNumber}</Typography>
                                        <Typography fontSize={'h5.fontSize'}>CUI: {usersData.CUI}</Typography>
                                        {/*<Typography fontSize={'h5.fontSize'}>Banca: {usersData.bank}</Typography>*/}
                                        {/*<Typography fontSize={'h5.fontSize'}>IBAN: {usersData.IBAN}</Typography>*/}
                                    </div>}

                                    </Grid>

                                    <Grid item xs = {4.5}>
                                        {URL ?
                                            <img src={URL} alt="" height={'100%'} width={'100%'} object-fit={'cover'} overflow={'hidden'}/>
                                        :
                                            <Box height={'115px'}>
                                            </Box>
                                        }

                                        <Grid container
                                              direction="column"
                                              justifyContent="center"
                                              alignItems="center"
                                        >
                                            <Grid item>
                                                <Box sx={{
                                                    lineHeight: 'large',
                                                    fontWeight: 400,
                                                    fontSize: 'h4.fontSize',
                                                }}>
                                                    DEVIZ
                                                </Box>
                                            </Grid>
                                            <Grid item>
                                                <Box sx={{
                                                    lineHeight: 'large',
                                                    fontWeight: 400,
                                                    fontSize: 'h5.fontSize',
                                                }}>
                                                   nr. ___ din {date}
                                                </Box>
                                            </Grid>
                                            <Grid item>
                                                <Box sx={{
                                                    lineHeight: 'normal',
                                                    fontWeight: 400,
                                                    fontSize: 'h5.fontSize',
                                                }}>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={7}>

                                            {clientData.clientType === 'company' &&  <Stack direction="column" alignItems="flex-end">
                                                <Typography fontSize={'h4.fontSize'}  fontWeight = {630} >Client: {clientData.company}</Typography>
                                                {
                                                    clientData.address && <Typography fontSize={'h5.fontSize'}>Adresa: {clientData.county} {clientData.city} {clientData.address} </Typography>
                                                }
                                                <Typography fontSize={'h5.fontSize'}>CUI: {clientData.CUI}</Typography>
                                                <Typography fontSize={'h5.fontSize'}>Reg. Com.: {clientData.commerceRegistrationNumber}</Typography>
                                                {/*<Typography fontSize={'h5.fontSize'}>Banca: {clientData.bank}</Typography>*/}
                                                {/*<Typography fontSize={'h5.fontSize'}>IBAN: {clientData.IBAN}</Typography>*/}
                                                <Typography fontSize={'h5.fontSize'}>Reprezentant: {clientData.name}  </Typography>

                                                <Typography fontSize={'h5.fontSize'}>Telefon: {clientData.phone}</Typography>
                                                {clientData.email && <Typography fontSize={'h5.fontSize'}>E-mail: {clientData.email}</Typography>}
                                            </Stack>
                                            }
                                        {clientData.clientType === 'person' && <Stack direction="column" alignItems="flex-end">
                                            <Typography fontSize={'h4.fontSize'} fontWeight = {630}>Client: {clientData.name}  </Typography>
                                            {
                                                (clientData.county || clientData.city || clientData.address) && <Typography fontSize={'h5.fontSize'}>Adresa: {clientData.county} {clientData.city} {clientData.address} </Typography>
                                            }
                                            <Typography fontSize={'h5.fontSize'}>Telefon: {clientData.phone}</Typography>
                                            {clientData.email && <Typography fontSize={'h5.fontSize'}>E-mail: {clientData.email}</Typography>}
                                        </Stack>}

                                    </Grid>

                                </Grid>



                                <Divider sx={{marginTop: '1', marginBottom: '1', borderBottomWidth: '5px' }}/>

                            <Grid container direction={'row'}  justifyContent={'flex-end'} width={'100%'} columns={21} >

                                <Grid item xs={13} paddingLeft={3}>

                                    {problemsDescribedByClient.length !== 0 &&
                                        <>
                                            <Grid container sx={{justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                                <Grid item>
                                                    <Typography>
                                                        <Box sx={{lineHeight: 'large',
                                                            fontWeight: 700,
                                                            fontSize: 'h5.fontSize',
                                                        }}>
                                                            DEFECȚIUNI RECLAMATE DE CLIENT
                                                        </Box>
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Typography
                                                fontSize={'h6.fontSize'}
                                            >{problemsDescribedByClient}</Typography>
                                        </>

                                    }

                                    {problemsFoundByService.length !== 0 &&
                                    <>
                                    <Grid container sx={{justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                        <Grid item>
                                            <Typography>
                                                <Box sx={{
                                                        lineHeight: 'large',
                                                        fontWeight: 700,
                                                        fontSize: 'h5.fontSize',
                                                        mt: 2,
                                                    }}>CERINȚE DE SERVICE</Box>

                                            </Typography>
                                        </Grid>

                                    </Grid>

                                    <Typography
                                                fontSize={'h6.fontSize'}
                                    >{problemsFoundByService}
                                    </Typography>
                                    </>

                                    }
                                    {receptionInfo.length !== 0 &&
                                        <>
                                        <Grid container sx={{justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                            <Grid item>
                                                <Typography>
                                                    <Box sx={{
                                                        lineHeight: 'large',
                                                        fontWeight: 700,
                                                        fontSize: 'h5.fontSize',
                                                        mt: 2,
                                                    }}> RECEPȚIE AUTOVEHICUL </Box>

                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Typography
                                                    fontSize={'h6.fontSize'}
                                        >{receptionInfo}</Typography>

                                        </>
                                    }

                                    {requirements.length != 0 &&
                                    <>
                                    <Grid container sx={{justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                        <Grid item>
                                            <Typography>

                                            <Box sx={{
                                                    lineHeight: 'large',
                                                    fontWeight: 700,
                                                    fontSize: 'h6.fontSize',
                                                    mt: 2,
                                                }}>CONSTATĂRI ÎN SERVICE</Box>

                                            </Typography>
                                        </Grid>

                                    </Grid>

                                        <Typography
                                                    fontSize={'h6.fontSize'}
                                        > {requirements} </Typography>
                                        </>
                                    }

                                </Grid >

                                <Grid item xs={1}>
                                    <Divider orientation={'vertical'} />
                                </Grid>

                                <Grid item xs={7} justifySelf={'flex-end'}>
                                    <Grid container
                                          direction="column"
                                          justifyContent="center"
                                          alignItems="flex-start"
                                    >
                                        <Grid item>
                                            <Typography sx={{
                                                marginLeft: '3vw'
                                            }}>
                                                <Box sx={{lineHeight: 'large',
                                                    fontWeight: 700,
                                                    fontSize: 'h5.fontSize',
                                                }}>
                                                    IDENTIFICARE AUTOVEHICUL
                                                </Box>

                                            </Typography>
                                        </Grid>

                                        <Grid item marginLeft = {'3vw'}>
                                            {carData.plate && <Typography
                                                fontSize={'h5.fontSize'}
                                            >Număr auto: <span style={{fontWeight: 'bold'}}>{carData.plate}</span> </Typography>}
                                        </Grid>
                                        <Grid item marginLeft = {'3vw'}>
                                            {carData.brand && <Typography
                                                fontSize={'h5.fontSize'}
                                            >Marcă: <span style={{fontWeight: 'bold'}}>{carData.brand}</span></Typography>}
                                        </Grid>
                                        <Grid item marginLeft = {'3vw'}>
                                            {carData.model && <Typography
                                                fontSize={'h5.fontSize'}
                                            >Model: <span style={{fontWeight: 'bold'}}>{carData.model} </span> </Typography>}
                                        </Grid>
                                        <Grid item marginLeft = {'3vw'}>
                                            {carData.year && <Typography
                                                fontSize={'h5.fontSize'}
                                            >An fabricație: {carData.year}</Typography>}
                                        </Grid>
                                        <Grid item marginLeft = {'3vw'}>
                                            {carData.vin && <Typography
                                                fontSize={'h5.fontSize'}
                                            >Serie sasiu: {carData.vin}</Typography>}
                                        </Grid>
                                        <Grid item marginLeft = {'3vw'}>
                                            {carData.capacity && <Typography
                                                fontSize={'h5.fontSize'}
                                            >Capacitate motor (CC): {carData.capacity}</Typography>}
                                        </Grid>
                                        <Grid item marginLeft = {'3vw'}>
                                            {carData.kw && <Typography
                                                fontSize={'h5.fontSize'}
                                            >Putere motor (kW): {carData.kw}</Typography>}
                                        </Grid>
                                        <Grid item marginLeft = {'3vw'}>
                                            {receptionData.mileage && <Typography
                                                fontSize={'h5.fontSize'}
                                            >Kilometri rulati: {receptionData.mileage}</Typography>}
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>




                            <Divider sx={{marginTop: '2vh', marginBottom: '2vh'}}/>

                    <Grid container direction={'row'}>
                        <Grid item xs={5.5}>
                            {partsData.parts.length !== 0 &&

                                <>
                                    <Grid container sx={{justifyContent: 'center', alignItems: 'flex-start'}}>
                                        <Grid item>
                                            <Typography sx={{
                                                marginLeft: '3vw'

                                            }}>
                                                <Box sx={{
                                                    lineHeight: 'large',
                                                    fontWeight: 700,
                                                    fontSize: 'h5.fontSize',
                                                }}>PIESE</Box>

                                            </Typography>
                                        </Grid>

                                    </Grid>

                                    <Grid container sx={{
                                        width: '100%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: 5
                                    }}>
                                        <Grid item sx={16}>
                                            <TableContainer>
                                                <Table sx={{ minWidth: 650 }}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{fontSize: 'h5.fontSize', padding: '0px', fontWeight: 'bold'}}>Piesa</TableCell>
                                                            <TableCell align="right"sx={{fontSize: 'h6.fontSize', padding: '0px', fontWeight: 'bold'}}>Preț</TableCell>
                                                            <TableCell align="right"sx={{fontSize: 'h6.fontSize', padding: '0px', fontWeight: 'bold'}}>Buc</TableCell>
                                                            <TableCell align="right"sx={{fontSize: 'h5.fontSize', padding: '0px', fontWeight: 'bold'}}>Total</TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {rowsParts.map((row) => (
                                                            <TableRow
                                                                key={row.part}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                                                            >
                                                                <TableCell component="th" scope="row" sx={{fontSize: 'h5.fontSize', padding: '0px'}}>
                                                                    {row.part}
                                                                </TableCell>
                                                                <TableCell align="right"sx={{fontSize: 'h6.fontSize', padding: '5px'}}>{row.price}</TableCell>
                                                                <TableCell align="right"sx={{fontSize: 'h6.fontSize', padding: '5px'}}>{row.quantity}</TableCell>
                                                                <TableCell align="right"sx={{fontSize: 'h5.fontSize', padding: '5px'}}>{parseInt(row.total)}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Grid>
                        <Grid item xs={0.5}>
                            <Divider orientation={'vertical'}/>
                        </Grid>
                        <Grid item xs={5.5}>

                    {hourlyLabourData.items.length !== 0
                        ?
                        <>
                            <Grid container sx={{justifyContent: 'center', alignItems: 'flex-start'}}>
                                <Grid item>
                                    <Typography sx={{
                                        marginLeft: '3vw'

                                    }}>
                                        <Box sx={{
                                            lineHeight: 'large',
                                            fontWeight: 700,
                                            fontSize: 'h5.fontSize',
                                        }}>LUCRĂRI ÎN SERVICE</Box>

                                    </Typography>
                                </Grid>

                            </Grid>


                                <Grid container sx={{
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: 5
                                }}>
                                    <Grid item>
                                        <TableContainer >
                                            <Table sx={{ minWidth: 650 }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={{fontSize: 'h5.fontSize', padding: '5px', fontWeight: 'bold'}}>Lucrare</TableCell>
                                                        <TableCell align="right" sx={{fontSize: 'h6.fontSize', padding: '5px', fontWeight: 'bold'}}>Tarif</TableCell>
                                                        <TableCell align="right" sx={{fontSize: 'h6.fontSize', padding: '5px', fontWeight: 'bold'}}>Ore</TableCell>
                                                        <TableCell align="right" sx={{fontSize: 'h5.fontSize', padding: '5px', fontWeight: 'bold'}}>Total</TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {rowsHourlyLabours.map((row) => (
                                                        <TableRow
                                                            key={row.item}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row" sx={{fontSize: 'h5.fontSize', padding: '0px'}}>
                                                                {row.item}
                                                            </TableCell>
                                                            <TableCell align="right" sx={{fontSize: 'h6.fontSize', padding: '5px'}}>{row.price}</TableCell>
                                                            <TableCell align="right" sx={{fontSize: 'h6.fontSize', padding: '5px'}}>{row.quantity}</TableCell>
                                                            <TableCell align="right" sx={{fontSize: 'h5.fontSize', padding: '5px'}}>{parseInt(row.total)}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            <Divider sx={{marginTop: '2vh', marginBottom: '2vh'}}/>

                        </>
                                :
                           <></>
                            }
                    {servicesData.items.length !== 0
                        ?
                        <>
                            <Grid container sx={{justifyContent: 'center', alignItems: 'flex-start'}}>
                                <Grid item>
                                    <Typography sx={{
                                        marginLeft: '3vw'

                                    }}>
                                        <Box sx={{
                                            lineHeight: 'large',
                                            fontWeight: 700,
                                            fontSize: 'h5.fontSize',
                                        }}>SERVICII</Box>

                                    </Typography>
                                </Grid>

                            </Grid>

                            <Grid container sx={{
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: 5
                            }}>
                                <Grid item>
                                    <TableContainer >
                                        <Table sx={{ minWidth: 650 }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{fontSize: 'h5.fontSize', padding: '5px', fontWeight: 'bold'}}>Serviciu</TableCell>
                                                    <TableCell align="right" sx={{fontSize: 'h6.fontSize', padding: '5px', fontWeight: 'bold'}}>Tarif</TableCell>
                                                    <TableCell align="right" sx={{fontSize: 'h6.fontSize', padding: '5px', fontWeight: 'bold'}}>Buc</TableCell>
                                                    <TableCell align="right" sx={{fontSize: 'h5.fontSize', padding: '5px', fontWeight: 'bold'}}>Total</TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {rowsServices.map((row) => (
                                                    <TableRow
                                                        key={row.item}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row" sx={{fontSize: 'h5.fontSize', padding: '0px'}}>
                                                            {row.item}
                                                        </TableCell>
                                                        <TableCell align="right" sx={{fontSize: 'h6.fontSize', padding: '5px'}}>{row.price}</TableCell>
                                                        <TableCell align="right" sx={{fontSize: 'h6.fontSize', padding: '5px'}}>{row.quantity}</TableCell>
                                                        <TableCell align="right" sx={{fontSize: 'h5.fontSize', padding: '5px'}}>{parseInt(row.total)}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <Divider sx={{marginTop: '2vh', marginBottom: '2vh'}}/>

                        </>
                        :
                        <></>
                    }

                    {finalVerificationsData.items.length !== 0
                        ?
                        <>
                            <Grid container sx={{justifyContent: 'center', alignItems: 'flex-start'}}>
                                <Grid item>
                                    <Typography sx={{
                                        marginLeft: '3vw'

                                    }}>
                                        <Box sx={{
                                            lineHeight: 'large',
                                            fontWeight: 700,
                                            fontSize: 'h5.fontSize',
                                        }}>VERIFICĂRI FINALE</Box>

                                    </Typography>
                                </Grid>

                            </Grid>


                            <Grid container sx={{
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: 5
                            }}>
                                <Grid item>
                                    <TableContainer>
                                        <Table sx={{ minWidth: 650 }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{fontSize: 'h5.fontSize', padding: '5px', fontWeight: 'bold'}}>Verificare</TableCell>
                                                    <TableCell align="right" sx={{fontSize: 'h5.fontSize', padding: '5px', fontWeight: 'bold'}}>Tarif</TableCell>
                                                    <TableCell align="right" sx={{fontSize: 'h5.fontSize', padding: '5px', fontWeight: 'bold'}}>Status</TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {rowsFinalVerifications.map((row) => (
                                                    <TableRow
                                                        key={row.item}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row" sx={{fontSize: 'h5.fontSize', padding: '0px'}}>
                                                            {row.item}
                                                        </TableCell>
                                                        <TableCell align="right" sx={{fontSize: 'h5.fontSize', padding: '0px'}}>{row.price}</TableCell>
                                                        <TableCell align="right" sx={{fontSize: 'h5.fontSize', padding: '0px'}}>{row.corresponds}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>                                    </Grid>
                            </Grid>
                            <Divider sx={{marginTop: '2vh', marginBottom: '2vh'}}/>

                        </>
                        :
                        <></>
                    }



                        </Grid>
                    </Grid>
                    <Divider sx={{marginTop: '2vh', marginBottom: '2vh'}}/>

                    <Grid container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="flex-start"
                          columns={21}
                          width={'100%'}
                    >

                        <Grid item xs={9}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="flex-end"
                            >
                                <Grid item>

                                    <Box sx={{
                                        fontWeight: 500,
                                        fontSize: 'h6.fontSize',
                                    }}>

                                        TOTAL PIESE FĂRĂ DISCOUNT: {calculateTotalNoDiscountParts()} RON
                                    </Box>

                                </Grid>
                                <Grid item>


                                    <Box sx={{
                                        fontWeight: 500,
                                        fontSize: 'h6.fontSize',
                                    }}>
                                        DISCOUNT: {calculateDiscountParts()} RON
                                    </Box>
                                </Grid>
                                <Grid item>

                                    <Box sx={{
                                        fontWeight: 500,
                                        fontSize: 'h6.fontSize',
                                    }}>
                                        TOTAL PIESE: {calculateTotalParts()} RON
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={1}>
                            <Divider orientation={'vertical'}/>
                        </Grid>

                        <Grid item xs={9}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="flex-end"
                                paddingRight={3}
                            >


                                <Grid item xs = {10}>
                                    <Box sx={{
                                        fontWeight: 500,
                                        fontSize: 'h6.fontSize',
                                    }}>

                                        TOTAL MANOPERĂ FĂRĂ DISCOUNT: {calculateTotalNoDiscountLabours()} RON
                                    </Box>

                                </Grid>
                                <Grid item xs = {10}>

                                    <Box sx={{
                                        fontWeight: 500,
                                        fontSize: 'h6.fontSize',
                                    }}>
                                        DISCOUNT: {calculateDiscountLabours()} RON
                                    </Box>
                                </Grid>
                                <Grid item xs = {10}>
                                    <Box sx={{
                                        fontWeight: 500,
                                        fontSize: 'h6.fontSize',
                                    }}>
                                        TOTAL MANOPERĂ: {calculateTotalLabours()} RON
                                    </Box>


                                </Grid>

                                <Divider/>


                            </Grid>
                        </Grid>
                    </Grid>

                            <Divider/>


                            <Grid container sx={{
                                width: '100%',
                                justifyContent: 'flex-end',
                                alignItems: 'flex-end',
                                paddingRight: 3,

                            }}>
                                <Grid item>
                                    <Typography>
                                        <Box sx={{
                                            fontWeight: 600,
                                            fontSize: 'h5.fontSize',
                                        }}>
                                            TOTAL DE PLATĂ: {calculateTotal()} RON
                                        </Box> </Typography>

                                </Grid>
                            </Grid>

                    <Divider/>


                    {recommendations.length != 0 &&

                    <Grid container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          columns={21}
                          width={'100%'}
                          padding={3}
                          paddingTop={0}
                          paddingBottom={0}
                    >
                        <Grid item>
                            <Box sx={{
                                lineHeight: 'large',
                                fontWeight: 700,
                                fontSize: 'h5.fontSize',
                            }}>RECOMANDĂRI</Box>

                            <Typography sx={{ fontSize: 'h6.fontSize'}}>{recommendations}</Typography>
                        </Grid>
                    </Grid>
                    }

                    <Divider sx={{marginTop: '2vh', marginBottom: '2vh', borderBottomWidth: '5px' }}/>


                    <Grid container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          columns={21}
                          width={'100%'}
                          padding={5}
                          paddingBottom={2}
                          paddingTop={0}
                    >
                        <Grid item>
                            <TableContainer>
                                <Table sx={{ minWidth: '70vw' }}>
                                    <TableHead>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                                        >
                                            <TableCell align="right" sx={{fontSize: 'h5.fontSize', padding: '0px', fontWeight: 'bold'}}>EXECUTANT</TableCell>
                                            <TableCell align="right" sx={{fontSize: 'h5.fontSize', padding: '0px', fontWeight: 'bold'}}>INSPECTIE FINALA</TableCell>
                                            <TableCell align="right" sx={{fontSize: 'h5.fontSize', padding: '0px', fontWeight: 'bold'}}>SEF SERVICE</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        <TableRow
                                            key={'executant'}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                                        >
                                            <TableCell component="th" scope="row" align="right"  sx={{fontSize: 'h5.fontSize', padding: '0px'}}>
                                                {mechanic ? mechanic.name : ""}
                                            </TableCell>
                                            <TableCell align="right" sx={{fontSize: 'h5.fontSize', padding: '0px'}}>
                                                {finalVerificationsMechanic ? finalVerificationsMechanic.name : ""}
                                            </TableCell>
                                            <TableCell align="right" sx={{fontSize: 'h5.fontSize', padding: '0px'}}>
                                                {supervisor ? supervisor.name : ""}
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>

                <Grid container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          columns={21}
                          width={'100%'}
                          padding={5}
                          paddingTop={0}
                          paddingBottom = {0}
                    >
                        <Grid item xs={13}>

                           <Typography sx={{fontWeight: 400,}}>Am verificat și preluat autovehiculul cu lucrările executate conform cu comanda de reparatie și inventar complet</Typography>
                            <Typography sx={{fontWeight: 400,}}>Clientul își asumă răspunderea pentru lucrările suplimentare recomandate de service și neacceptate de către client</Typography>
                            <Typography sx={{fontWeight: 400,}}>Garanția pentru manoperă este de 45 de zile, conform contractului de reparatie.</Typography>
                            <Typography sx={{fontWeight: 400,}}>Garanția pentru piese este conform OG 140/2021, cu respectarea cerintelor specificate de producător și în condițiile de exploatare indicate de fabricant, in conformitate cu Contractul de reparatie</Typography>

                        </Grid>

                        <Grid item xs={7} padding={0}>
                            <Typography sx={{
                                marginLeft: '3vw',
                                fontWeight: 'bold',
                                fontSize: 'h5.fontSize'
                            }}>
                                Client
                            </Typography>
                            <Typography sx={{
                                marginLeft: '3vw',
                                fontSize: 'h6.fontSize'
                            }}>
                                {clientData.name}
                            </Typography>
                            <Typography sx={{
                                marginLeft: '3vw',
                                fontSize: 'h6.fontSize'
                            }}>
                                Semnatura
                            </Typography>
                        </Grid>
                    </Grid>


                </div>

        );
};
