import * as React from 'react';
import MechanicProfile from "./MechanicProfile";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box'
import AddMechanic from "./AddMechanic";
import {collection, getDocs} from "@firebase/firestore";
import {db} from "../../authentification/firebase";
import {useAuth} from "../../../contexts/AuthContext";
import {useEffect, useState} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

function Mechanics({mechanics, newMechanicAdded, setNewMechanicAdded, supervisor, setSupervisor,
                       finalVerificationsMechanic, setFinalVerificationsMechanic
                        }) {

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
    }, [newMechanicAdded])

    useEffect(() => {
        if (loading)
            setTimeout(() => {
                setLoading(false)
            }, 100)

    }, [loading])

    return (
        <div>
            {mechanics.length !== 0 && <Autocomplete

                id="country-select-demo"
                value={{
                    id: supervisor.id,
                    name: supervisor.name,
                    imageURL: supervisor.imageURL
                }}
                sx={{
                    width: 300,
                    paddingLeft: 5,
                }}
                options={mechanics}
                autoHighlight
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                    <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                        <img
                            loading="lazy"
                            width="20"
                            src={option.imageURL}
                            alt=""
                            style={{
                                maxHeight: '200px',
                                borderRadius: '50%',
                                aspectRatio: '1',
                                objectFit: 'cover',
                                overflow: 'hidden',
                                ':hover': {
                                    borderRadius: '20%'
                                },
                            }}
                        />
                        {option.name}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Desemneaza sef de service"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}

                        onSelect={(event) => {
                            mechanics.forEach((e) => {
                                if (e.name === event.target.value) {
                                    setSupervisor({
                                        id: e.id,
                                        name: e.name,
                                        imageURL: e.imageURL
                                    })
                                    return
                                }
                            })
                        }}
                        size={'large'}
                    />
                )}


            />}

            {mechanics.length !== 0 && <Autocomplete
                id="country-select-demo"
                value={{
                    id: finalVerificationsMechanic.id,
                    name: finalVerificationsMechanic.name,
                    imageURL: finalVerificationsMechanic.imageURL
                }}
                sx={{ width: 300,
                    paddingLeft: 5,
                    paddingTop: 3

                }}
                options={mechanics}
                autoHighlight
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                            loading="lazy"
                            width="20"
                            src={option.imageURL}
                            alt=""
                            style={{maxHeight: '200px', borderRadius: '50%', aspectRatio: '1', objectFit: 'cover', overflow: 'hidden',
                                ':hover': {
                                    borderRadius: '20%'
                                },
                            }}
                        />
                        {option.name}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Desemneaza un mecanic pentru verificari finale"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}

                        onSelect={(event) => {
                            mechanics.forEach((e) => {
                                if (e.name === event.target.value) {
                                    setFinalVerificationsMechanic({
                                        id: e.id,
                                        name: e.name,
                                        imageURL: e.imageURL
                                    })
                                    return
                                }
                            })
                        }}
                        size={'large'}
                    />
                )}


            />}

            <Box sx={{ maxWidth: '80vw', maxHeight: '100vh', overflow: 'auto', marginTop: '7vh'}}>
                {loading ?
                <div>

                </div>
                :
                <Grid container m={2} sm={12} sx={{alignItems: 'center', justifyContent: 'flex-start', paddingLeft: '10vw'}}>

                    <Grid item xs={4} sx={{ maxWidth: '20vw', marginBottom: '10vh'}}>
                        <AddMechanic setNewMechanicAdded = {setNewMechanicAdded} newMechanicAdded = {newMechanicAdded}/>
                    </Grid>
                    {mechanics.sort(function (a, b) {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    }).map((mechanic) =>  <Grid item xs={4} sx={{ maxWidth: '20vw', marginBottom: '10vh'}}> <MechanicProfile name = {mechanic.name} imageURL = {mechanic.imageURL}
                                         setNewMechanicAdded = {setNewMechanicAdded} newMechanicAdded = {newMechanicAdded} id = {mechanic.id}
                        /> </Grid>)}
                </Grid>
                }
            </Box>
        </div>

    );


}

export default Mechanics;