import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button'
import Grid from "@mui/material/Grid";
import {useState, useEffect} from "react";
import Typography from "@mui/material/Typography";
import {doc, getDoc, setDoc} from "@firebase/firestore";
import {db} from "../authentification/firebase";
import {useAuth} from "../../contexts/AuthContext";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";

function AddReceptionInfo({receptionData, setReceptionData, addReceptionData, helperText}) {

    const handleSaveRecommendations = async () => {
        recommendationsList &&
        recommendationsList.length !== 0 &&
        await setDoc(doc(db, `${uid}lists`, 'recommendations'), {
            recommendationsList,
            toggleRecommendationsAutomaticAdd
        })
    }
    const [toggleRecommendationsAutomaticAdd, setToggleRecommendationsAutomaticAdd] = useState(false);
    const [recommendationsList, setRecommendationsList] = useState()

    const currentUser = useAuth().currentUser
    const uid = currentUser.uid

    const handleAddNewInfo = () => {
        if (receptionData.newInfo === '')
            ;
        else {
            const receptionInfo = receptionData.info.slice(0)
            var adauga = true;
            for (var i=0; i<receptionInfo.length; i++)
                if (receptionInfo[i] === receptionData.newInfo)
                    adauga = false
            if (adauga)
                receptionInfo.push(receptionData.newInfo)
            setReceptionData({...receptionInfo, info: receptionInfo, newInfo: ''})

        }
    }

    useEffect(() => {
        let docRef = null
        let docSnap = null

        const getRecommendationsData = async () => {
            try{
                docRef = await doc(db,  `${uid}lists`, 'recommendations')
                docSnap = await getDoc(docRef);
            }
            catch(e){
            }
        }

        getRecommendationsData().then(() => {
                try{
                    let data = docSnap.data()
                    setRecommendationsList(data.recommendationsList)
                    setToggleRecommendationsAutomaticAdd(data.toggleRecommendationsAutomaticAdd)
                }

                catch (e) {

                }

            }
        )
    }, [])

    useEffect(() => {
        if (recommendationsList && recommendationsList.length !== 0)
            handleSaveRecommendations()
    }, [recommendationsList, toggleRecommendationsAutomaticAdd])

    const addNewRecommendationToOptionsList = () => {
        const newRecommendationsList = []
        let add = true

        if (recommendationsList && recommendationsList.length !== 0){
            recommendationsList.forEach((element) => {
                if (element.item === receptionData.newInfo)
                    add = false;
                newRecommendationsList.push({
                    item: element.item,
                })
            })
        }
        newRecommendationsList.push({
            item: receptionData.newInfo,
        })
        if (add && toggleRecommendationsAutomaticAdd) {
            setRecommendationsList(newRecommendationsList)
        }
    }

    return (
        <Grid container spacing={3} direction={'row'} justifyContent={'space-between'} alignItems={'center'} width = 'auto' marginBottom={'3vh'}>
            {/*<Grid item>*/}
            {/*    <Typography fontSize={'140%'}>*/}
            {/*        {helperText}*/}
            {/*    </Typography>*/}
            {/*</Grid>*/}
            <Grid item>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={toggleRecommendationsAutomaticAdd}
                                onChange={() => {
                                    setToggleRecommendationsAutomaticAdd(!toggleRecommendationsAutomaticAdd)
                                }}
                            />
                        }
                        label={'Salveaza detaliile automat'}
                    />
                </FormGroup>
            </Grid>
            <Grid item>
                <Autocomplete
                    size={'small'}
                    freeSolo
                    options={recommendationsList ? recommendationsList.map( e => e.item) : []}
                    sx={{width: 300}}
                    value = {receptionData.newInfo}
                    renderInput={(params) => <TextField{...params} label={addReceptionData} onChange={
                        (event) => setReceptionData({
                            ...receptionData,
                            newInfo: event.target.value
                        })}
                                                       onSelect = {
                                                           (event) => setReceptionData({
                                                               ...receptionData,
                                                               newInfo: event.target.value
                                                           })}
                    />}
                />

            </Grid>

            <Grid item>

                <Button variant="outlined"
                        onClick={() => {
                            handleAddNewInfo()
                            addNewRecommendationToOptionsList()
                        }}
                >
                    Adaugă</Button>

            </Grid>
        </Grid>
    );
}


export default AddReceptionInfo