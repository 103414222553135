import * as React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import {useState, setState} from "react";
import AddReceptionInfo from './AddReceptionInfo'
import ReceptionInfoList from "./ReceptionInfoList";
import TextField from "@mui/material/TextField";
import {Dialog, Tab, Tabs} from "@mui/material";
import EstimatePreview from "../invoiceGenerator/EstimatePreview";
import Box from "@mui/material/Box";
import ReceptionDocument from "./images/ReceptionDocument";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";

function VehicleReception({ setReceptionData, currentReceptionTab, setCurrentReceptionTab,
                              partsData, carData, problemsData, foundProblemsData, receptionData, problemsDict,
                              hourlyLabourData, servicesData, finalVerificationsData,
                              labourDiscount, labourDiscountType, partsDiscount, partsDiscountType,
                              recommendationsData, previewDocumentRef, clientData,
                              date,
                              usersData, URL, supervisor
}) {

    const [openPreviewDocument, setOpenPreviewDocument] = useState(false);


    const downloadDocument = () => {
        const input = previewDocumentRef.current;

        html2canvas(input
            // , {allowTaint: true, foreignObjectRendering: true, scrollY: -window.scrollY}
        ).then((canvas) => {
            const imgWidth = 208;
            const pageHeight = 295;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;
            heightLeft -= pageHeight;
            const doc = new jsPDF('p', 'mm');
            doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
                heightLeft -= pageHeight;
            }
            doc.save("Comanda de reparatie " + carData.plate + ".pdf");
        });
    };



    const handleChange = (event, newValue) => {
        setCurrentReceptionTab(newValue);
    };

    return (
        <React.Fragment>
                <div>
                    <Button
                        onClick={() => {setOpenPreviewDocument(true)}}
                        variant={'outlined'}
                        disableRipple={true}
                    >Comanda de reparatie</Button>

                    <Dialog open={openPreviewDocument}
                        onClose={() => {setOpenPreviewDocument(false)}}
                            fullWidth = {false}
                            maxWidth={'1000px'}
                        >
                            <IconButton color={'success'}
                                        disableRipple={true}
                                        onClick={() => {
                                            downloadDocument()
                                        }}>

                                <DownloadIcon />
                                DESCARCA DOCUMENTUL
                            </IconButton>
                            <ReceptionDocument partsData={partsData}
                                               carData={carData}
                                               problemsData={problemsData}
                                               foundProblemsData={foundProblemsData}
                                               receptionData={receptionData}
                                               problemsDict={problemsDict}
                                               hourlyLabourData={hourlyLabourData}
                                               servicesData={servicesData}
                                               finalVerificationsData={finalVerificationsData}
                                               partsDiscountType={partsDiscountType}
                                               partsDiscount={partsDiscount}
                                               labourDiscountType={labourDiscountType}
                                               labourDiscount={labourDiscount}
                                               recommendationsData={recommendationsData}
                                               previewDocumentRef = {previewDocumentRef}
                                               clientData={clientData}
                                               usersData = {usersData}
                                               URL = {URL}
                                               date = {date}
                                               supervisor = {supervisor}
                            />
                    </Dialog>
                <Grid container spacing={2} pt={'3vh'}>
                    <Grid item xs={8} md={3}>
                        <TextField
                            optional
                            id="mileage"
                            label="Kilometraj"
                            fullWidth
                            defaultValue={receptionData.mileage}
                            onChange={(event) => {
                                event.target.value = event.target.value.toString().replace(/\D/g, '')
                                setReceptionData({...receptionData, mileage: event.target.value})
                            }
                            }
                        />

                    </Grid>
                </Grid>

                <Grid container spacing={2} paddingTop={'5vh'}>
                <AddReceptionInfo receptionData = {receptionData} setReceptionData = {setReceptionData}
                addReceptionData={"Detalii"}
                helperText = "În ce stare se prezintă mașina?"
                >
                </AddReceptionInfo>
                </Grid>

                <Grid container spacing={1}>
                <Grid container spacing={1} direction={'column'} justifyContent={'space-evenly'} alignItems={'flex-stat'}>
                <Grid item xs={12} sm={'auto'}>

            {receptionData.info.length === 0
                ?
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                <img src={require('./images/mechanic-empty-details-list.png')}
                alt="Inca nu exista detalii adaugate in deviz"
                style={{
                width: 'auto',
                height: 'auto',
                objectFit: 'cover',
                maxWidth: '60vw',
                maxHeight: '60vh'
            }}
                />
                </div>
                :
                <ReceptionInfoList receptionData={receptionData} setReceptionData={setReceptionData}>
                </ReceptionInfoList>
            }
                </Grid>
                </Grid>
                </Grid>
                </div>




        </React.Fragment>
    );
}

export default VehicleReception