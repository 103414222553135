import React from 'react';
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {FormControlLabel, FormGroup, Tab, Tabs} from "@mui/material";
import {useState} from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Card from '@mui/material/Card';
import SearchIcon from "@mui/icons-material/Search";
import {useEffect} from 'react'
import {useAuth} from "../../../contexts/AuthContext";
import {ref} from "firebase/storage";
import {db, storage} from "../../authentification/firebase";
import {doc, getDoc, setDoc} from "@firebase/firestore";
import {Checkbox} from "@mui/material";

function ContractualConditions(props) {
    return (
        <div>

        </div>
    );
}

export default ContractualConditions;