import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EstimateClick from "./EstimateClick";
import {useRef, useState} from "react";
import {db, storage} from './authentification/firebase'
import {useEffect} from "react";
import {collection, getDocs, doc, deleteDoc, setDoc, getDoc, addDoc} from "@firebase/firestore";
import {useAuth} from "../contexts/AuthContext";
import SuccessSnackBar from "./alerts/SuccessSnackBar";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {Autocomplete, Dialog, FormControl, InputLabel, Pagination, Select, TextField} from "@mui/material";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import ClosedEstimateClick from "./ClosedEstimateClick";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from "@mui/material/IconButton";
import secureLocalStorage from "react-secure-storage";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import Paper from "@mui/material/Paper";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Card from "@mui/material/Card";
import Slide from "@mui/material/Slide";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";

const theme = createTheme();
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(5),
        marginBottom: 0,

        paddingTop: 0,
        border: 0,
        '&.Mui-disabled': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));

function dateCheck(dateFrom,dateTo,dateCheck) {

    var d1 = dateFrom.split("/");
    var d2 = dateTo.split("/");
    var c = dateCheck.split("/");

    var from = new Date(d1[2], parseInt(d1[1])-1, d1[0]);  // -1 because months are from 0 to 11
    var to   = new Date(d2[2], parseInt(d2[1])-1, d2[0]);
    var check = new Date(c[2], parseInt(c[1])-1, c[0]);

    return (check >= from && check <= to)
}


function Estimates() {



    // new estimate

    const [clientData, setClientData] = useState({
        clientType: 'person',
        name: '',
        email: '',
        phone: '',
        address: '',
        company: '',
        CUI: '',
        commerceRegistrationNumber: '',
        bank: '',
        IBAN: '',
    });

    const [carData, setCarData] = useState({
        vin: '',
        plate: '',
        brand: '',
        model: '',
        year: '',
        type: '',
        capacity: '',
        motorCode: '',
        fuel: '',
        color: '',
        kw: '',
    });

    const [problemsData, setProblemsData] = useState({
        problems: [],
        newProblem: '',

    });

    const [foundProblemsData, setFoundProblemsData] = useState({
        problems: [],
        newProblem: '',

    });


    const [labourDiscount, setLabourDiscount] = useState(0)
    const [labourDiscountType, setLabourDiscountType] = useState('percentage')

    const [partsDiscount, setPartsDiscount] = useState(0)
    const [partsDiscountType, setPartsDiscountType] = useState('percentage')

    const [problemsDict, setProblemsDict] = useState([])

    const [partsData, setPartsData] = useState({
        parts: [],
        newPart: ''
    })

    const [receptionData, setReceptionData] = useState({
        info: [],
        newInfo: '',
        mileage: ''
    })

    const [recommendationsData, setRecommendationsData] = useState({
        recommendations: [],
        newRecommendation: ''
    })

    const [currentLabourTab, setCurrentLabourTab] = useState(0)
    const [currentReceptionTab, setCurrentReceptionTab] = useState(0)

    const [activeStep, setActiveStep] = React.useState(0);
    const [step, setStep] = React.useState('client');
    const [error, setError] = useState("")
    const [newError, setNewError] = useState(true)

    const [deletionError, setDeletionError] = useState("")
    const [newDeletionError, setNewDeletionError] = useState(false)

    const navigate = useNavigate();

    const [showCode, setShowCode] = useState(false)
    const [showMaker, setShowMaker] = useState(false)
    const [showInfo, setShowInfo] = useState(false)

    const [requirementsHourlyLabourData, setRequirementsHourlyLabourData] = useState([]);
    const [requirementsServicesData, setRequirementsServicesData] = useState([]);
    const [requirementsFinalVerificationsData, setRequirementsFinalVerificationsData] = useState([]);


    const [hourlyLabourData, setHourlyLabourData] = useState({
        items: [],
        options: [],
        newItem: ''
    })

    const [servicesData, setServicesData] = useState({
        items: [],
        options: [],
        newItem: ''
    })

    const [finalVerificationsData, setFinalVerificationsData] = useState({
        items: [],
        options: [],
        newItem: ''
    })

    const [mechanic, setMechanic] = useState({
        id: '',
        name: '',
        imageURL: ''
    })

    const [currentRepairRequirementsTab, setCurrentRepairRequirementsTab] = useState(0)


    // end new estimate



    let data = ""


    const currentUser = useAuth().currentUser
    const uid = currentUser.uid
    const deviz = collection(db, `${uid}`)


    const [info, setInfo] =  useState([]);
    const [deletedEstimate, setDeletedEstimate] = useState(false);

    let estimatesRef = collection(db, `${uid}`) // luam colectia de devize din firestore
    let closedEstimatesRef =  collection(db, `${uid}`+'closed')

    let carHistoryRef =  collection(db, `${uid}`+'carHistory')
    let clientHistoryRef =  collection(db, `${uid}`+'clientHistory')


    const [pageRefreshed, setPageRefreshed] = useState(false);

    const [pageNumber, setPageNumber] = useState(1);
    const [numberOfElements, setNumberOfElements] = useState(10);

    const [closedEstimates, setClosedEstimates] =  useState([]);

    const [searchValue, setSearchValue] = useState("")
    const [closedEstimatesSearchValue, setClosedEstimatesSearchValue] = useState("")

    const [elementWasArchived, setElementWasArchived] = useState(false)
    const [archivedElement, setArchivedElement] = useState(false);

    const [loading, setLoading] = useState(false)
    const maxEstimatesAllowed = 20
    const [newEstimateQueried, setNewEstimateQueried] = useState(false)
    const [estimateCreated, setEstimateCreated] = useState(false)

    const [carHistoryDataFinishedLoading, setCarHistoryDataFinishedLoading] = useState(false)
    const [clientHistoryDataFinishedLoading, setClientHistoryDataFinishedLoading] = useState(false)

    const [carPlatesOptions, setCarPlatesOptions] = useState([])
    const [clientOptions, setClientOptions] = useState([])


    const validateEstimate = () => {
        if ( clientData.name === "" || clientData.phone === "")
        {
            setError("Datele clientului sunt incomplete")
            setNewError(true)
            return false

        }
        else if (carData.plate === "")
        {
            setError("Datele autovehiculului sunt incomplete")
            setNewError(true)
            return false

        }
        return true
    }

    useEffect(() => {
        if (!carHistoryDataFinishedLoading || !clientHistoryDataFinishedLoading)
            return
        let date = new Date().getDate().toString() + '/' + (new Date().getMonth() + 1).toString() + '/' + new Date().getFullYear().toString()

        data = {
            mechanic,
            clientData,
            carData,
            problemsData,
            foundProblemsData,
            labourDiscount,
            labourDiscountType,
            partsDiscount,
            partsDiscountType,
            problemsDict,
            partsData,
            receptionData,
            recommendationsData,
            currentLabourTab,
            currentReceptionTab,
            activeStep,
            step,
            error,
            newError,
            deletionError,
            newDeletionError,
            showCode,
            showMaker,
            showInfo,
            requirementsHourlyLabourData,
            requirementsServicesData,
            requirementsFinalVerificationsData,
            hourlyLabourData,
            servicesData,
            finalVerificationsData,
            currentRepairRequirementsTab,
            date: date
        }


        addDoc(deviz, data);
        setEstimateCreated(true)
        setCarData({...carData, plate: ''})
        setClientData({...clientData, phone: '', name: ''})
        setNewEstimateQueried(false)
        newInformation()
        setCarHistoryDataFinishedLoading(false)
        setClientHistoryDataFinishedLoading(false)
    }, [carHistoryDataFinishedLoading, clientHistoryDataFinishedLoading])


    const createNewEstimate = () => {
        let docSnapCarHistory

        let docSnapClientHistory

        let carDataFromHistory = null
        let clientDataFromHistory = null

        if (!validateEstimate())
            return

        // get car info from history, if existing

        const getCarHistoryData = async () => {
            try{
                carHistoryRef = await doc(db,  `${uid}carHistory`, carData.plate)
                docSnapCarHistory = await getDoc(carHistoryRef);
            }
            catch(e){
            }
        }

        const getClientHistoryData = async () => {
            try{
                clientHistoryRef = await doc(db,  `${uid}clientHistory`, clientData.name + ', ' + clientData.phone)
                docSnapClientHistory = await getDoc(clientHistoryRef);
            }
            catch(e){
            }
        }

        try{
            let date = new Date().getDate().toString() + '/' + (new Date().getMonth() + 1).toString() + '/' + new Date().getFullYear().toString()

            getCarHistoryData().then(() => {
                    try{
                        carDataFromHistory = docSnapCarHistory.data()
                        if (carDataFromHistory) {
                            setCarData(carDataFromHistory)
                        }
                        setCarHistoryDataFinishedLoading(true)
                    }
                    catch (e){

                    }
                }
            )

            getClientHistoryData().then(() => {
                    try{
                        clientDataFromHistory = docSnapClientHistory.data()
                        if (clientDataFromHistory) {
                            setClientData(clientDataFromHistory)
                        }
                        setClientHistoryDataFinishedLoading(true)
                    }
                    catch (e){

                    }
                }
            )
        }
        catch(e){
        }
    }

    const newInformation = () => {
        // setLoading(true)
        const getEstimates = async () => {
            const data = await getDocs(estimatesRef)
            setInfo(data.docs.map((doc) => ({...doc.data(), id: doc.id, date: doc.data().date})))
            // setLoading(false)
        }
        getEstimates()
        getPlatesHistory()
        getClientsHistory()
    }

    const getEstimates = async () => {
        const data = await getDocs(estimatesRef)
        setInfo(data.docs.map((doc) => ({...doc.data(), id: doc.id, date: doc.data().date})))
        // setLoading(false)
    }

    const getPlatesHistory = async () => {
        let arrayOfPlates = []
        const data = (await getDocs(carHistoryRef)).docs.forEach(doc => (arrayOfPlates.push(doc.id)))
        setCarPlatesOptions(arrayOfPlates)
        // setLoading(false)
    }

    const getClientsHistory = async () => {
        let arrayOfCustomers = []
        const data = (await getDocs(clientHistoryRef)).docs.forEach(doc => (arrayOfCustomers.push(doc.id)))
        setClientOptions(arrayOfCustomers)
        // setLoading(false)
    }
    const newClosedEstimatesInformation = () => {
        // setLoading(true)
        const getClosedEstimates = async () => {
            const data = await getDocs(closedEstimatesRef)
            setClosedEstimates(data.docs.map((doc) => ({...doc.data(), id: doc.id, date: doc.data().date})))
        }
        getClosedEstimates()
        // setLoading(false)
    }

    const [usersData, setUsersData] =  useState({});

    const [company, setCompany] = useState("");
    const [address, setAddress] = useState("");
    const [workAddress, setWorkAddress] = useState("");
    const [CUI, setCUI] = useState("");
    const [comerceRegisterNumber, setComerceRegisterNumber] = useState("");
    const [bank, setBank] = useState("");
    const [IBAN, setIBAN] = useState("");

    const [imageUpload, setImageUpload] = useState(null);
    const [URL, setURL] = useState("");

    const [startDate, setStartDate] = useState('25/1/2022')
    let today = new Date().getDate().toString() + '/' + (new Date().getMonth() + 1).toString() + '/' + new Date().getFullYear().toString()

    const [endDate, setEndDate] = useState (today)

    const storageRef = ref(storage,`images/${uid}`)
    const [mechanics, setMechanics] = useState([])
    const mechanicsRef = collection(db, `${uid}mechanics`) // luam colectia de devize din firestore

    const [supervisor, setSupervisor] = useState({
        id: '',
        imageURL: '',
        name: ''
    })

    const [finalVerificationsMechanic, setFinalVerificationsMechanic] = useState({
        id: '',
        imageURL: '',
        name: ''
    })

    const getMechanics = async () => {
        const data = await getDocs(mechanicsRef).then()
        setMechanics(data.docs.filter(doc => (doc.id !== 'supervisor' && doc.id !== 'finalVerificationsMechanic')).map((doc) => ({...doc.data(), id: doc.id})))
    }



    useEffect(() => {
        // newInformation()
        // newClosedEstimatesInformation()
        setPageRefreshed(true)
        let docRef = null
        let docSnap = null
        // uid = currentUser.uid
        // estimatesRef = collection(db, `${uid}`)

        getDownloadURL(storageRef).then((currentURL) => {
            setURL(currentURL);
        })

            .catch((error)=>{
            })

        const getUsersData = async () => {
            try{
                docRef = await doc(db, "Users", uid)
                docSnap = await getDoc(docRef);
            }
            catch(e){
            }
            finally {
            }
        }

        getUsersData().then(()=> {
            data = docSnap.data()
            // setUsersData(data)
            if (data) {
                setUsersData(data)
                setCompany(data.company)
                setIBAN(data.IBAN)
                setBank(data.bank)
                setCUI(data.CUI)
                setComerceRegisterNumber(data.comerceRegisterNumber)
                setWorkAddress(data.workAddress)
                setAddress(data.address)
            }
        })

        getMechanics()

        let supervisorRef
        let supervisorSnap
        let finalVerificationsMechanicRef
        let finalVerificationsMechanicSnap

        const getSupervisor = async () => {
            try {
                supervisorRef = await doc(db,  `${uid}mechanics`, 'supervisor')
                supervisorSnap = await getDoc(supervisorRef);
            }
            catch (e){
            }

        }
        getSupervisor().then(() => {
            try{
                let data = supervisorSnap.data()
                if (data)
                    setSupervisor(data)
            }

            catch (e) {

            }

        })

        const getFinalVerificationsMechanic = async () => {
            try {
                finalVerificationsMechanicRef = await doc(db,  `${uid}mechanics`, 'finalVerificationsMechanic')
                finalVerificationsMechanicSnap = await getDoc(finalVerificationsMechanicRef);
            }
            catch (e){
            }

        }
        getFinalVerificationsMechanic().then(() => {
            try{
                let data = finalVerificationsMechanicSnap.data()
                if (data)
                    setFinalVerificationsMechanic(data)
            }

            catch (e) {

            }

        })
    }, []);

    useEffect(()=>{
        if (searchValue === '')
            newInformation()

        let newInfo = []
        info.forEach((element) => {
            if (dateCheck(startDate, endDate, element.date))
                if (element.clientData.name.toLowerCase().includes(searchValue.toLowerCase())
                    ||
                    element.carData.plate.toLowerCase().includes(searchValue.toLowerCase())
                    ||
                    element.clientData.phone.includes(searchValue.toLowerCase())
                    ||
                    element.carData.brand.toLowerCase().includes(searchValue.toLowerCase())
                    ||
                    element.date.toLowerCase().includes(searchValue.toLowerCase())
            )
                newInfo.push(element)
        });
        setInfo(newInfo)
    }, [searchValue])

    useEffect(()=>{
        if (closedEstimatesSearchValue === '')
            newClosedEstimatesInformation()

        let newEstimates = []
        closedEstimates.forEach((element) => {
            if (dateCheck(startDate, endDate, element.date))
                if (element.clientData.name.toLowerCase().includes(closedEstimatesSearchValue.toLowerCase())
                ||
                element.carData.plate.toLowerCase().includes(closedEstimatesSearchValue.toLowerCase())
                ||
                element.clientData.phone.includes(closedEstimatesSearchValue.toLowerCase())
                ||
                element.carData.brand.toLowerCase().includes(closedEstimatesSearchValue.toLowerCase())
                ||
                element.date.toLowerCase().includes(closedEstimatesSearchValue.toLowerCase())
            )
                newEstimates.push(element)
        });
        setClosedEstimates(newEstimates)
    }, [closedEstimatesSearchValue])

    const deleteElement = (id) => {
        const docRef = doc(db, `${uid}`, id);
        deleteDoc(docRef)
            .then(() => {
                setDeletedEstimate(!deletedEstimate)
                setPageRefreshed(false)
            })
            .catch(error => {
            })
        const findID = (el) => el.id === id
        info.splice(info.findIndex(findID), 1)
    }
    const deleteClosedEstimate = (id) => {
        const docRef = doc(db, `${uid}`+'closed', id);
        deleteDoc(docRef)
            .then(() => {
                setDeletedEstimate(!deletedEstimate)
                setPageRefreshed(false)
            })
            .catch(error => {
            })
        const findID = (el) => el.id === id
        closedEstimates.splice(closedEstimates.findIndex(findID), 1)
    }



    async function archiveElement (id) {
        const docRef = doc(db, `${uid}`, id);
        const data = info.find(e => e.id === id)

        addDoc(closedEstimatesRef, data)
        newClosedEstimatesInformation()
        deleteDoc(docRef)
            .then(() => {
                setDeletedEstimate(!deletedEstimate)
                setArchivedElement(!archivedElement)
                setElementWasArchived(true)
            })
            .catch(error => {
            })
        const findID = (el) => el.id === id
        info.splice(info.findIndex(findID), 1)

        const today = new Date()
        const day = today.getDate().toString().padStart(2, '0');
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const year = today.getFullYear();
        const date = `${day}.${month}.${year}`; // name of collection

        let totalPrice = 0
        data.hourlyLabourData.items.forEach(e => {totalPrice = totalPrice + parseFloat(e.total)}) // the total price of hourly labours for current estimate
        data.servicesData.items.forEach(e => {totalPrice = totalPrice + parseFloat(e.total)}) // the total price of services for current estimate
        data.finalVerificationsData.items.forEach(e => {totalPrice = totalPrice + parseFloat(e.price)}) // the total price of final verifications for current estimate

        let performanceDict = {}
        mechanics.forEach(mechanic => {performanceDict[mechanic.id] = 0}) // create an empty dictionary with {currentMechanic: 0}
        performanceDict[data.mechanic.id] = totalPrice // the mechanic that worked on this car should have their performance increased with the total of the labour

        const performanceDateRef = doc(db, `${uid}performance`, date)
        const performanceDoc = await getDoc(performanceDateRef)

        if (performanceDoc.exists()){
            // if the owner closed some estimates today, a document with statistics already exists, hence we UPDATE the performance statistics
            performanceDict = performanceDoc.data()
            performanceDict[data.mechanic.id] += totalPrice
            const performanceCollection = collection(db, `${uid}performance`);

            await setDoc(doc(performanceCollection,date), performanceDict)
        }
        else {
            // if the owner did NOT close any estimates today, no document with statistics exists for today, hence we create one
            const performanceCollection = collection(db, `${uid}performance`);
            await setDoc(doc(performanceCollection,date), performanceDict)
        }

        await setDoc(doc(db, `${uid}carHistory`, data.carData.plate), data.carData)
        await setDoc(doc(db, `${uid}clientHistory`, data.clientData.name + ', ' + data.clientData.phone), data.clientData)

    }

    const [alignment, setAlignment] = React.useState('open');

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment)
            setAlignment(newAlignment);
    };


    if (loading)
        return (<div></div>)

    return (

        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Container display="flex" component="main"  sx={{ mb: 4, alignItems: 'center', justifyContent: 'flex-start'}}>
                    <StyledToggleButtonGroup
                        size={'large'}
                        value={alignment}
                        exclusive
                        onChange={handleAlignment}
                        fullWidth
                    >
                        <ToggleButton value={'open'}>
                          <Typography sx={{fontWeight: '500', fontSize: '20px'}} paddingTop={2}> Devize active</Typography>
                        </ToggleButton>

                        <ToggleButton value={'closed'}>
                          <Typography sx={{fontWeight: '500', fontSize: '20px'}} paddingTop={2}> Devize închise</Typography>
                        </ToggleButton>
                    </StyledToggleButtonGroup>

            </Container>

            <AppBar
                position="absolute"
                color="default"
                elevation={3}
                sx={{
                    position: 'relative',
                    borderBottom: (t) => `2px solid ${t.palette.divider}`,
                    zIndex: 0
                }}
            >

            </AppBar>
            {alignment === "open" &&

                <Grid container columns={21} >
                <Grid item xs={7} >
                    {/*<Container sx={{marginTop: 7, marginLeft: 10}}  >*/}
                    {/*    <LocalizationProvider dateAdapter={AdapterDayjs} >*/}
                    {/*        <DatePicker*/}
                    {/*            slotProps={{*/}
                    {/*                textField: {*/}
                    {/*                    helperText: 'Data de început',*/}
                    {/*                },*/}
                    {/*            }}*/}
                    {/*            onChange={(value)=>{*/}
                    {/*                setStartDate(value.$D + '/' + String(value.$M+1) + '/' + value.$y)*/}
                    {/*            }}*/}
                    {/*            format={'DD/MM/YYYY'}*/}
                    {/*        />*/}
                    {/*        <br/>*/}
                    {/*        <br/>*/}
                    {/*        <br/>*/}

                    {/*        <DatePicker*/}
                    {/*                    slotProps={{*/}
                    {/*                        textField: {*/}
                    {/*                            helperText: 'Data de final',*/}
                    {/*                        },*/}
                    {/*                    }}*/}
                    {/*                    onChange={(value)=>{*/}
                    {/*                        setEndDate(value.$D + '/' + String(value.$M+1) + '/' + value.$y)*/}

                    {/*                    }}*/}
                    {/*                    format={'DD/MM/YYYY'}*/}
                    {/*        />*/}
                    {/*        <br/>*/}
                    {/*        <Button onClick={() =>{*/}
                    {/*            let newInfo = []*/}
                    {/*            info.forEach((element) => {*/}
                    {/*                if (dateCheck(startDate, endDate, element.date))*/}
                    {/*                    newInfo.push(element)*/}
                    {/*            });*/}
                    {/*            setInfo(newInfo)*/}
                    {/*            }}>CAUTĂ</Button>*/}
                    {/*    </LocalizationProvider>*/}
                    {/*</Container>*/}
                </Grid>
                    <Grid item xs={7}>
                        <Container component="main" maxWidth="sm" sx={{ mb: 4, justifyContent: 'space-between', pt: 5}}>
                            <Paper variant="outlined" sx={{
                                my: {xs: 3, md: 1},
                                p: {xs: 1, md: 3},
                                display: 'flex',
                                justifyContent: 'space-between'

                            }} onClick={() => {
                                setNewEstimateQueried(true)
                                setEstimateCreated(false)
                            }}
                            >
                                <Stack direction="column" alignItems="center" justifyContent={'center'} spacing={3} width={'100%'}>
                                    <AddCircleIcon fontSize={"large"} color={'primary'}/>
                                </Stack>
                            </Paper>
                                <Dialog
                                    open={newEstimateQueried}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={() => {
                                        setNewEstimateQueried(false)
                                        setCarData({...carData, plate: ''})
                                        setClientData({...clientData, phone: '', name: ''})
                                    }}
                                    aria-describedby="alert-dialog-slide-description"
                                    PaperProps={{ sx: {minWidth: '50vw' } }}
                                    sx={{
                                        backdropFilter: "blur(5px) sepia(5%)",
                                    }}

                                >

                                    <Grid container spacing={1} padding={'10%'}>
                                        <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                freeSolo
                                                value={carData.plate}
                                                options={carPlatesOptions}
                                                onChange={(event, value) => setCarData ({...carData, plate: value.toUpperCase()})}
                                                onSelect = {
                                                    (event) => {
                                                        setCarData ({...carData, plate: event.target.value.toUpperCase()})
                                                    }}

                                                renderInput={(params) => (
                                                    <TextField{...params}
                                                              required
                                                              id="plate"
                                                              error = {error !== "" && carData.plate === ""}
                                                              label="Numar de înmatriculare"
                                                              fullWidth
                                                              onChange={(event) => {
                                                                  event.target.value = event.target.value.toString().toUpperCase()
                                                                  event.target.value = event.target.value.toString().replace(/[^a-zA-Z0-9]/i, '')

                                                                  setCarData({...carData, plate: event.target.value.toUpperCase()})
                                                              }}
                                                              inputProps={{
                                                                  ...params.inputProps,
                                                                  style: { textTransform: "uppercase" }
                                                              }}
                                                    />
                                                )
                                            }/>

                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Autocomplete

                                                freeSolo
                                                value={clientData.name}
                                                options={clientOptions}
                                                onChange={(event, value) => {
                                                        try{
                                                            setClientData({...clientData, name: value.split(', ')[0], phone: value.split(', ')[1]})
                                                        }

                                                        catch(e) {
                                                            console.log(e)
                                                        }
                                                    }}

                                                onSelect={(event) => {
                                                  console.log(event.target.value)
                                                }}


                                                renderInput={(params) => (
                                                    <TextField{...params}
                                                              onClick={() => {
                                                                  console.log(clientData)}
                                                              }
                                                        required
                                                        error={error !== "" && clientData.name === ""}
                                                        label="Nume și prenume"
                                                        fullWidth
                                                        autoComplete="family-name"
                                                          onChange={(event) => {
                                                              event.target.value = event.target.value.toString().replace(/[^a-zA-Z ]/i, '')
                                                              setClientData({...clientData, name: event.target.value})
                                                          }}
                                                              inputProps={{
                                                                  ...params.inputProps,
                                                              }}
                                                    />
                                                )
                                                }/>

                                        </Grid>


                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                required
                                                error={error !== "" && clientData.phone === ""}

                                                label="Telefon"
                                                fullWidth
                                                value={clientData.phone}
                                                onChange={(event) => {
                                                    event.target.value = event.target.value.toString().replace(/[^+0-9]/i, '')
                                                    setClientData({...clientData, phone: event.target.value})
                                                }
                                                }
                                            />

                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}
                                          direction="row"
                                          justifyContent="flex-end"
                                          alignItems="center"
                                          lg
                                          padding={'5%'}
                                    >
                                        <Grid item>
                                            <Button variant="outlined"
                                                    onClick={createNewEstimate}
                                                    sx={{color: 'green', borderColor: 'green'}}
                                            > Creeaza deviz </Button>
                                        </Grid>
                                    </Grid>
                                </Dialog>
                            {info.sort((firstEstimate, secondEstimate) => {
                                let parts = firstEstimate.date.split("/");
                                let firstDate = new Date(parts[2], parts[1] - 1, parts[0]);

                                parts = secondEstimate.date.split("/");
                                let secondDate = new Date(parts[2], parts[1] - 1, parts[0]);

                                if (firstDate.getTime() < secondDate.getTime())
                                    return 1;
                                if (firstDate.getTime() > secondDate.getTime())
                                    return -1;
                                return 0;
                            }).slice((pageNumber-1)*numberOfElements, pageNumber*numberOfElements).map((element) => {
                                    return(
                                        <div sx={{display: 'grid'}}>
                                                <EstimateClick element = {element} info = {info} setInfo= {setInfo} deleteElement={deleteElement} usersData = {usersData} URL = {URL}
                                                               archiveElement = {archiveElement}
                                                               mechanics={mechanics}
                                                               openEstimate={false} // TODO make estimate open after being created
                                                               supervisor = {supervisor}
                                                               finalVerificationsMechanic = {finalVerificationsMechanic}
                                                />
                                        </div>)
                                }
                            )}

                            <Stack justifyContent={'flex-end'} alignItems={"center"}>
                                <Pagination count={Math.ceil(info.length/numberOfElements)} color="primary" size={'large'}
                                            hideNextButton = {false}
                                            hidePrevButton = {false}
                                            onChange={(event, value)=>{
                                                setPageNumber(value)
                                            }}
                                />
                            </Stack>

                        </Container>
                    </Grid>
                    <Grid item xs={7} >
                        <Container justifyContent={'flex-start'} alignItems={'flex-end'} sx={{marginTop: 7}}>
                            <TextField size={'small'}
                                       label={'Caută un deviz'}
                                       onChange={(event)=>{
                                    setSearchValue(event.target.value)
                                }}
                            />
                            <IconButton disabled={true}>
                                <SearchIcon color={'primary'}
                                />
                            </IconButton>
                        </Container>


                    </Grid>
            </Grid>
            }

            {alignment==='closed' &&
                <Grid container columns={21}>
                    <Grid item xs={7} >

                    </Grid>

                    <Grid item xs={7}>
                        <Container component="main" maxWidth="sm" sx={{ mb: 4, justifyContent: 'space-between', pt: 5}}>

                {closedEstimates.sort((firstEstimate, secondEstimate) => {
                    let parts = firstEstimate.date.split("/");
                    let firstDate = new Date(parts[2], parts[1] - 1, parts[0]);

                    parts = secondEstimate.date.split("/");
                    let secondDate = new Date(parts[2], parts[1] - 1, parts[0]);

                    if (firstDate.getTime() < secondDate.getTime())
                        return 1;
                    if (firstDate.getTime() > secondDate.getTime())
                        return -1;
                    return 0;
                }).slice((pageNumber-1)*numberOfElements, pageNumber*numberOfElements).map((element) => {
                        return(
                            <div sx={{display: 'grid'}}>
                                <ClosedEstimateClick element = {element} info = {info} setInfo= {setInfo} deleteElement={deleteClosedEstimate} usersData = {usersData} URL = {URL}/>
                            </div>)
                    }
                )}
                        </Container>
                <Stack justifyContent={'flex-end'} alignItems={"center"}>
                    <Pagination count={Math.ceil(closedEstimates.length/numberOfElements)} color="primary" size={'large'}
                                hideNextButton = {false}
                                hidePrevButton = {false}
                                onChange={(event, value)=>{
                                    setPageNumber(value)
                                }}
                    />

                    {/*<FormControl>*/}
                    {/*    <Grid container direction={'row'} sx={{width: 'auto', paddingTop: '3vh', alignItems: 'center'}}>*/}
                    {/*        <Grid item marginRight={3}>*/}
                    {/*            <Typography>*/}
                    {/*                Număr de devize pe pagină*/}
                    {/*            </Typography>*/}
                    {/*        </Grid>*/}

                    {/*         <Grid item>*/}
                    {/*             <Select*/}
                    {/*                 sx={{width: '10'}}*/}
                    {/*                 defaultValue={numberOfElements}*/}
                    {/*                 label="Număr de devize pe pagină"*/}
                    {/*                 onChange={(event)=>{*/}
                    {/*                     setNumberOfElements(event.target.value)*/}
                    {/*                     setPageNumber(1)*/}
                    {/*                 }}*/}
                    {/*                 size={'small'}*/}
                    {/*             >*/}
                    {/*                 <MenuItem value={5}>5</MenuItem>*/}
                    {/*                 <MenuItem value={10}>10</MenuItem>*/}
                    {/*                 <MenuItem value={15}>15</MenuItem>*/}
                    {/*             </Select>*/}
                    {/*        </Grid>*/}

                    {/*    </Grid>*/}

                    {/*</FormControl>*/}
                </Stack>
                    </Grid>
                    <Grid item xs={7} >
                        <Container justifyContent={'flex-start'} alignItems={'flex-end'} sx={{marginTop: 7}}>

                            <TextField size={'small'}
                                       label={'Caută un deviz'}
                                       onChange={(event)=>{
                                setClosedEstimatesSearchValue(event.target.value)
                            }}
                            />

                            <IconButton disabled={true}>
                                <SearchIcon color={'primary'}
                                />
                            </IconButton>
                        </Container>


                    </Grid>


            </Grid>}
            {!pageRefreshed && <SuccessSnackBar message = "Devizul a fost șters cu succes!" refreshOn={deletedEstimate}/>}
            {elementWasArchived && <SuccessSnackBar message = "Devizul a fost arhivat cu succes!" refreshOn={archivedElement}/>}

        </ThemeProvider>
    );
}

export default Estimates;