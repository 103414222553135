import * as React from 'react';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import {useState, setState} from "react";
import AddProblem from './AddProblem'
import ProblemsList from "./ProblemsList";
import ServiceRequirementsList from "./ServiceRequirementsList";
import AddServiceRequirement from "./AddServiceRequirement";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box'

function ProblemsFoundByService({foundProblemsData, setFoundProblemsData, problemsDict, setProblemsDict,otherProblemsData, deletionError, newDeletionError, setNewDeletionError, setDeletionError,
                                    hourlyLabourData, setHourlyLabourData,
                                    servicesData, setServicesData,
                                    mechanics,
                                    mechanic, setMechanic
                                }) {

    return (
        <React.Fragment>

            <Grid container spacing={2}>
                <Grid container spacing={3} direction={'row'} justifyContent={'space-between'} alignItems={'center'} width = 'auto' marginBottom={'3vh'}>
                    <Grid item>
                        <Autocomplete
                            id="country-select-demo"
                            value={{
                                    id: mechanic.id,
                                    name: mechanic.name,
                                    imageURL: mechanic.imageURL
                                }}
                            sx={{ width: 300 }}
                            options={mechanics}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    <img
                                        loading="lazy"
                                        width="20"
                                        src={option.imageURL}
                                        alt=""
                                        style={{maxHeight: '200px', borderRadius: '50%', aspectRatio: '1', objectFit: 'cover', overflow: 'hidden',
                                            ':hover': {
                                                borderRadius: '20%'
                                            },
                                        }}
                                    />
                                    {option.name}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Alocă un mecanic"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}

                                    onSelect={(event) => {
                                        mechanics.forEach((e) => {
                                            if (e.name === event.target.value) {
                                                setMechanic({
                                                    id: e.id,
                                                    name: e.name,
                                                    imageURL: e.imageURL
                                                })
                                                return
                                            }
                                        })
                                    }}
                                    size={'large'}
                                />
                            )}


                        />

                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <AddServiceRequirement problemsData = {foundProblemsData} setProblemsData = {setFoundProblemsData} addProblemLabel = {"Cerință de service"}
                            problemsDict = {problemsDict} setProblemsDict = {setProblemsDict}
                                       hourlyLabourData={hourlyLabourData} setHourlyLabourData={setHourlyLabourData}
                                       servicesData={servicesData} setServicesData={setServicesData}
                >
                </AddServiceRequirement>
            </Grid>

            <Grid container spacing={1}>
                <Grid container spacing={1} direction={'column'} justifyContent={'space-evenly'} alignItems={'flex-stat'}>
                    <Grid item xs={12} sm={'auto'}>
                        {foundProblemsData.problems.length === 0
                            ?
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                                <img src={require('./images/mechanic-empty-service-problems-list.png')}  alt="Inca nu exista defectiuni adaugate in deviz"
                                     style={{ width: 'auto', height: 'auto', objectFit: 'cover', maxWidth: '60vw', maxHeight: '60vh'}}
                                />
                            </div>
                            :
                            <ServiceRequirementsList problemsData = {foundProblemsData} setProblemsData = {setFoundProblemsData} problemsDict={problemsDict}
                            otherProblemsData={otherProblemsData} deletionError = {deletionError} setDeletionError = {setDeletionError} newDeletionError = {newDeletionError}  setNewDeletionError = {setNewDeletionError}
                                hourlyLabourData={hourlyLabourData} setHourlyLabourData={setHourlyLabourData}
                                servicesData={servicesData} setServicesData={setServicesData}/>

                        }
                    </Grid>
                </Grid>
            </Grid>


        </React.Fragment>
    );
}

export default ProblemsFoundByService