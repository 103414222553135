import React, {useRef} from "react";
import { Page, Text, Image, Document, StyleSheet, PDFViewer} from "@react-pdf/renderer";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { DataGrid } from '@mui/x-data-grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 24,
        textAlign: "center",
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: "justify",
        fontFamily: "Times-Roman",
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: "center",
        color: "grey",
    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },
});

export default function ReceptionDocument({partsData, carData, problemsData, foundProblemsData, receptionData, problemsDict,
                                            hourlyLabourData, servicesData, finalVerificationsData,
                                            labourDiscount, labourDiscountType, partsDiscount, partsDiscountType,
                                            recommendationsData, previewDocumentRef, clientData,
                                            date,
                                            usersData, URL, supervisor
                                        }) {

    const calculateTotalNoDiscountParts = () => {
        let sum = 0
        for (let i=0; i<partsData.parts.length; i++)
            sum += parseFloat(partsData.parts[i].total)
        return sum.toFixed(2)
    }

    const calculateDiscountParts = () => {
        let sum = calculateTotalNoDiscountParts()
        if (partsDiscountType === 'ammount')
            return parseFloat(partsDiscount).toFixed(2)
        else return (sum*(partsDiscount)/100).toFixed(2)
    }

    const calculateTotalParts = () => {
        return (calculateTotalNoDiscountParts() - calculateDiscountParts()).toFixed(2)
    }

    const calculateTotalHourlyLabour = () => {
        let sum = 0
        for (let i=0; i<hourlyLabourData.items.length; i++)
            sum += parseFloat(hourlyLabourData.items[i].total)
        return sum.toFixed(2)
    }

    const calculateTotalServices = () => {
        let sum = 0
        for (let i=0; i<servicesData.items.length; i++)
            sum += parseFloat(servicesData.items[i].total)
        return sum.toFixed(2)
    }

    const calculateTotalFinalVerifications = () => {
        let sum = 0
        for (let i=0; i<finalVerificationsData.items.length; i++)
            sum += parseFloat(finalVerificationsData.items[i].price)
        return sum.toFixed(2)
    }

    const calculateTotalNoDiscountLabours= () => {
        return (parseFloat(calculateTotalFinalVerifications()) + parseFloat(calculateTotalServices()) + parseFloat(calculateTotalHourlyLabour())).toFixed(2)
    }

    const calculateDiscountLabours = () => {
        let sum = calculateTotalNoDiscountLabours()
        if (labourDiscountType === 'ammount')
            return (parseFloat(labourDiscount).toFixed(2))
        else return (sum*(labourDiscount)/100).toFixed(2)
    }

    const calculateTotalLabours = () => {
        return (calculateTotalNoDiscountLabours() - calculateDiscountLabours()).toFixed(2)
    }

    const calculateTotal = () => {
        return (parseFloat(calculateTotalLabours()) + parseFloat(calculateTotalParts())).toFixed(2)
    }



    let problemsDescribedByClient = ''
    problemsData.problems.map((element) => (
        problemsDescribedByClient=problemsDescribedByClient.concat(element, '; ')
    ))
    problemsDescribedByClient=problemsDescribedByClient.slice(0,-2)


    let problemsFoundByService = ''
    foundProblemsData.problems.map((element) => (
        problemsFoundByService=problemsFoundByService.concat(element.problem, '; ')
    ))
    problemsFoundByService=problemsFoundByService.slice(0,-2)

    let receptionInfo = ''
    receptionData.info.map((element) => (
        receptionInfo=receptionInfo.concat(element, '; ')
    ))
    receptionInfo=receptionInfo.slice(0,-2)

    let req = []
    problemsDict.map(element => {
        req = req.concat(element.value.problems)
    })
    req = [...new Set(req)];

    let requirements = ""
    req.map((element) => (
        requirements=requirements.concat(element, '; ')
    ))
    requirements=requirements.slice(0,-2)


    let recommendations = ''
    recommendationsData.recommendations.map((element) => (
        recommendations=recommendations.concat(element, '; ')
    ))
    recommendations = recommendations.slice(0,-2)


    const rowsParts = []
    partsData.parts.forEach((part) => {
        rowsParts.push({
            part: part.part,
            price: part.price,
            quantity: part.quantity,
            total: part.total
        })
    })


    const rowsHourlyLabours = []
    hourlyLabourData.items.forEach((item) => {
        rowsHourlyLabours.push({
            item: item.item,
            price: item.price,
            quantity: item.quantity,
            total: item.total
        })
    })

    const rowsServices = []
    servicesData.items.forEach((item) => {
        rowsServices.push({
            item: item.item,
            price: item.price,
            quantity: item.quantity,
            total: item.total
        })
    })

    const rowsFinalVerifications = []
    finalVerificationsData.items.forEach((item) => {
        rowsFinalVerifications.push({
            item: item.itemName,
            price: item.price,
            corresponds: item.corresponds
        })
    })




    const pdfRef = useRef();

    return (
        <div ref={previewDocumentRef} style={{width: '1500px'}}>

            {/*car information*/}


            <Grid container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  columns={21}
                  width={'100%'}
                  padding={5}
            >
                <Grid item xs={7}>{usersData && <div>

                    <Typography fontSize={'h4.fontSize'} fontWeight={630}>{usersData.company}</Typography>
                    <Typography fontSize={'h5.fontSize'}>Sediu social: {usersData.address}</Typography>
                    <Typography fontSize={'h5.fontSize'}>Punct de lucru: {usersData.workAddress}</Typography>
                    <Typography fontSize={'h5.fontSize'}>Reg. Com.: {usersData.comerceRegisterNumber}</Typography>
                    <Typography fontSize={'h5.fontSize'}>CUI: {usersData.CUI}</Typography>
                    {/*<Typography fontSize={'h5.fontSize'}>Banca: {usersData.bank}</Typography>*/}
                    {/*<Typography fontSize={'h5.fontSize'}>IBAN: {usersData.IBAN}</Typography>*/}
                </div>}

                </Grid>

                <Grid item xs = {4.5}>
                    {/*{URL &&*/}
                    {/*    <img src={URL} alt="" height={'100%'} width={'100%'} object-fit={'cover'} overflow={'hidden'} />}*/}
                    <Box height={'115px'}>

                    </Box>
                    <Grid container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                    >
                        <Grid item>
                            <Box sx={{
                                lineHeight: 'large',
                                fontWeight: 400,
                                fontSize: 'h5.fontSize',
                            }}>
                                Proces-verbal de predare-primire
                                in vederea reparatiei (Comanda de reparatie)
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box sx={{
                                lineHeight: 'large',
                                fontWeight: 400,
                                fontSize: 'h5.fontSize',
                            }}>
                                nr. ___ din {date}
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box sx={{
                                lineHeight: 'normal',
                                fontWeight: 400,
                                fontSize: 'h5.fontSize',
                            }}>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={7}>

                    {clientData.clientType === 'company' &&  <Stack direction="column" alignItems="flex-end">
                        <Typography fontSize={'h4.fontSize'}  fontWeight = {630} >Client: {clientData.company}</Typography>
                        {
                            clientData.address && <Typography fontSize={'h5.fontSize'}>Adresa: {clientData.county} {clientData.city} {clientData.address} </Typography>
                        }
                        <Typography fontSize={'h5.fontSize'}>CUI: {clientData.CUI}</Typography>
                        <Typography fontSize={'h5.fontSize'}>Reg. Com.: {clientData.commerceRegistrationNumber}</Typography>
                        {/*<Typography fontSize={'h5.fontSize'}>Banca: {clientData.bank}</Typography>*/}
                        {/*<Typography fontSize={'h5.fontSize'}>IBAN: {clientData.IBAN}</Typography>*/}
                        <Typography fontSize={'h5.fontSize'}>Reprezentant: {clientData.name}  </Typography>

                        <Typography fontSize={'h5.fontSize'}>Telefon: {clientData.phone}</Typography>
                        {clientData.email && <Typography fontSize={'h5.fontSize'}>E-mail: {clientData.email}</Typography>}
                    </Stack>
                    }
                    {clientData.clientType === 'person' && <Stack direction="column" alignItems="flex-end">
                        <Typography fontSize={'h4.fontSize'} fontWeight = {630}>Client: {clientData.name}  </Typography>
                        {
                            (clientData.county || clientData.city || clientData.address) && <Typography fontSize={'h5.fontSize'}>Adresa: {clientData.county} {clientData.city} {clientData.address} </Typography>
                        }
                        <Typography fontSize={'h5.fontSize'}>Telefon: {clientData.phone}</Typography>
                        {clientData.email && <Typography fontSize={'h5.fontSize'}>E-mail: {clientData.email}</Typography>}
                    </Stack>}

                </Grid>

            </Grid>



            {/*<Divider sx={{marginTop: '2vh', marginBottom: '2vh'}}/>*/}

            <Divider sx={{marginTop: '1', marginBottom: '1', borderBottomWidth: '5px' }}/>

            <Grid container direction={'row'}  justifyContent={'flex-end'} width={'100%'} columns={21} >

                <Grid item xs={13} paddingLeft={3}>
                    <Grid container sx={{justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                        <Grid item>
                            <Typography>
                                <Box sx={{lineHeight: 'large',
                                    fontWeight: 700,
                                    fontSize: 'h5.fontSize',
                                }}>
                                    DEFECȚIUNI RECLAMATE DE CLIENT
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>

                    {problemsDescribedByClient.length !== 0
                        ?
                        <Typography
                            fontSize={'h5.fontSize'}
                        >{problemsDescribedByClient}</Typography>
                        :
                        <Typography
                            fontSize={'h5.fontSize'}
                        >-</Typography>
                    }

                    <Grid container sx={{justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                        <Grid item>
                            <Typography>
                                <Box sx={{
                                    lineHeight: 'large',
                                    fontWeight: 700,
                                    fontSize: 'h5.fontSize',
                                    mt: 2,
                                }}>CERINȚE DE SERVICE</Box>

                            </Typography>
                        </Grid>

                    </Grid>
                    {problemsFoundByService.length !== 0
                        ?
                        <Typography
                            fontSize={'h5.fontSize'}
                        >{problemsFoundByService}</Typography>
                        :
                        <Typography
                            fontSize={'h5.fontSize'}
                        >-</Typography>
                    }

                    <Grid container sx={{justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                        <Grid item>
                            <Typography>
                                <Box sx={{
                                    lineHeight: 'large',
                                    fontWeight: 700,
                                    fontSize: 'h5.fontSize',
                                    mt: 2,
                                }}> RECEPȚIE AUTOVEHICUL </Box>

                            </Typography>
                        </Grid>

                    </Grid>
                    {receptionInfo.length !== 0
                        ?
                        <Typography
                            fontSize={'h5.fontSize'}
                        >{receptionInfo}</Typography>
                        :
                        <Typography
                            fontSize={'h5.fontSize'}
                        >-</Typography>
                    }

                    <Grid container sx={{justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                        <Grid item>
                            <Typography>

                                <Box sx={{
                                    lineHeight: 'large',
                                    fontWeight: 700,
                                    fontSize: 'h5.fontSize',
                                    mt: 2,
                                }}>CONSTATĂRI ÎN SERVICE</Box>

                            </Typography>
                        </Grid>

                    </Grid>
                    {requirements.length != 0
                        ?
                        <Typography
                            fontSize={'h5.fontSize'}
                        > {requirements} </Typography>
                        :
                        <Typography
                            fontSize={'h5.fontSize'}
                        >-</Typography>
                    }

                </Grid >

                <Grid item xs={1}>
                    <Divider orientation={'vertical'} />
                </Grid>

                <Grid item xs={7} justifySelf={'flex-end'}>
                    <Grid container
                          direction="column"
                          justifyContent="center"
                          alignItems="flex-start"
                    >
                        <Grid item>
                            <Typography sx={{
                                marginLeft: '3vw'
                            }}>
                                <Box sx={{lineHeight: 'large',
                                    fontWeight: 700,
                                    fontSize: 'h5.fontSize',
                                    mt: 2
                                }}>
                                    IDENTIFICARE AUTOVEHICUL
                                </Box>

                            </Typography>
                        </Grid>

                        <Grid item marginLeft = {'3vw'}>
                            {carData.plate && <Typography
                                fontSize={'h5.fontSize'}
                            >Număr auto: <span style={{fontWeight: 'bold'}}>{carData.plate}</span> </Typography>}
                        </Grid>
                        <Grid item marginLeft = {'3vw'}>
                            {carData.brand && <Typography
                                fontSize={'h5.fontSize'}
                            >Marcă: <span style={{fontWeight: 'bold'}}>{carData.brand}</span></Typography>}
                        </Grid>
                        <Grid item marginLeft = {'3vw'}>
                            {carData.model && <Typography
                                fontSize={'h5.fontSize'}
                            >Model: <span style={{fontWeight: 'bold'}}>{carData.model} </span> </Typography>}
                        </Grid>
                        <Grid item marginLeft = {'3vw'}>
                            {carData.year && <Typography
                                fontSize={'h5.fontSize'}
                            >An fabricație: {carData.year}</Typography>}
                        </Grid>
                        <Grid item marginLeft = {'3vw'}>
                            {carData.vin && <Typography
                                fontSize={'h5.fontSize'}
                            >Serie sasiu: {carData.vin}</Typography>}
                        </Grid>
                        <Grid item marginLeft = {'3vw'}>
                            {carData.capacity && <Typography
                                fontSize={'h5.fontSize'}
                            >Capacitate motor (CC): {carData.capacity}</Typography>}
                        </Grid>
                        <Grid item marginLeft = {'3vw'}>
                            {carData.kw && <Typography
                                fontSize={'h5.fontSize'}
                            >Putere motor (kW): {carData.kw}</Typography>}
                        </Grid>
                        <Grid item marginLeft = {'3vw'}>
                            {receptionData.mileage && <Typography
                                fontSize={'h5.fontSize'}
                            >Kilometri rulati: {receptionData.mileage}</Typography>}
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>




            <Divider sx={{marginTop: '2vh', marginBottom: '2vh'}}/>

            <Grid container direction={'row'}>
                <Grid item xs={7.55} paddingLeft={3}>
                    <>
                        <Grid container sx={{justifyContent: 'center', alignItems: 'flex-start'}}>
                            <Grid item>
                                <Typography sx={{
                                    marginLeft: '3vw'

                                }}>


                                </Typography>
                            </Grid>

                        </Grid>

                        <Grid container sx={{
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 5,
                            fontSize: 'h5.fontSize'
                        }}>
                            <Grid item sx={16}>
                                <p>Prin semnarea prezentului “Proces verbal de predare-primire” (Comanda de reparatie), Clientul declara ca este de
                                    acord cu:</p>
                                <p>1. Conditiile contractuale afisate in locatia Prestatorului si/sau pe pagina web a acestuia
                                </p>
                                <p>2. Timpul de reparatie estimat la: ____ zile
                                </p>
                                <p>3. Costul reparatiei de aproximativ: _______lei.
                                </p>
                                <p>4. Clientul solicita un Deviz estimativ: DA / NU
                                </p>
                                <p>5. Accepta lucrari suplimentare: DA / NU
                                </p>
                                <p>6. Returnarea pieselor inlocuite, inclusiv a filtrelor: DA / NU
                                </p>
                                <p>7. Prelucrarea datelor cu caracter personal
                                </p>
                                <p>8. Prevederile din RNTR 9, pct. 5.5.4. .2 privind utilizarea in cadrul reparatiilor a pieselor reconditionate, reparate sau
                                    second hand furnizate de Prestator sau de Client.</p>




                            </Grid>
                        </Grid>
                    </>
                </Grid>

                <Grid item xs={0.5} padding={1}>
                    <Divider orientation={'vertical'}/>
                </Grid>
                <Grid item xs={3.45} padding={3}>
                    <>
                        <Grid container sx={{justifyContent: 'center', alignItems: 'flex-start'}}>
                            <Grid item>
                                <Typography sx={{
                                    marginLeft: '3vw'

                                }}>


                                </Typography>
                            </Grid>

                        </Grid>

                        <Grid container sx={{
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 5,
                            fontSize: 'h5.fontSize'
                        }}>
                            <Grid item sx={16}>
                                <p>Prin prezentul “Proces verbal de predare-primire”, Clientul preda in vederea reparatiei autovehiculul descris mai sus
                                    insotit de urmatoarele documente:
                                </p>
                                <p>□ Certificat de inmatriculare </p>
                                <p>□ Dovada de circulatie provizorie </p>
                                <p>□ Carte de identitate </p>
                                <p>□ Asigurare</p>
                                <p>□ Altele _____________________ </p>

                            </Grid>
                        </Grid>
                    </>


                </Grid>

                <Divider sx={{marginTop: '2vh', marginBottom: '2vh'}}/>

            </Grid>

            <Divider sx={{marginTop: '1', marginBottom: '1', borderBottomWidth: '5px' }}/>



            <Grid container direction={'row'} justifyContent={'space-between'} paddingTop={5}>
                    <Grid item xs={5} padding={0}>
                        <Typography sx={{
                            marginLeft: '3vw',
                            fontWeight: 'bold',
                            fontSize: 'h5.fontSize'
                        }}>
                            Predare autovehicul
                        </Typography>
                        <Typography sx={{
                            marginLeft: '3vw',
                            fontWeight: 'bold',
                            fontSize: 'h5.fontSize'
                        }}>
                            Client / Reprezentant Client
                        </Typography>
                        <Typography sx={{
                            marginLeft: '3vw',
                            fontWeight: 'bold',
                            fontSize: 'h5.fontSize'
                        }}>
                            {clientData.name}
                        </Typography>
                        <Typography sx={{
                            marginLeft: '3vw',
                            fontSize: 'h5.fontSize'
                        }}>
                            (Semnatura)
                        </Typography>
                    </Grid>

                    <Grid item xs={5} padding={0}>
                                <Typography sx={{
                                    marginLeft: '3vw',
                                    fontWeight: 'bold',
                                    fontSize: 'h5.fontSize'
                                }}>
                                    Primire autovehicul
                                </Typography>
                                <Typography sx={{
                                    marginLeft: '3vw',
                                    fontWeight: 'bold',
                                    fontSize: 'h5.fontSize'
                                }}>
                                    Prestator / reprezentant Prestator
                                </Typography>
                                <Typography sx={{
                                    marginLeft: '3vw',
                                    fontWeight: 'bold',
                                    fontSize: 'h5.fontSize'
                                }}>
                                    {supervisor.name}
                                </Typography>
                                <Typography sx={{
                                    marginLeft: '3vw',
                                    fontSize: 'h5.fontSize'
                                }}>
                                    (Semnatura)
                                </Typography>
                    </Grid>
            </Grid>
        </div>

    );
};
