import React, { useContext, useState, useEffect } from 'react'
import {auth, db} from '../components/authentification/firebase'
import LogIn from "../components/authentification/LogIn";
import Register from "../components/authentification/Register";
import  secureLocalStorage  from  "react-secure-storage";
import {collection, doc, getDocs, setDoc} from "@firebase/firestore";

const AuthContext = React.createContext()

export function useAuth(){
    return useContext(AuthContext)
}

export function AuthProvider({ children }){
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    function createTemplateData(email, password){
        const getServicesData = async () => {
            try{
                let templateCollectionRef = await collection(db,  'templateLists') // change with updated collection name if you want to update
                let templateCollection = await getDocs(templateCollectionRef)

                auth.createUserWithEmailAndPassword(email, password).then(function(data){
                    templateCollection.docs.map((document) => {
                        setDoc(doc(db,`${data.user.uid}lists`, document.id), document.data()) // change with templateLists to save the new templateLists collection
                    })

                }).catch(function(e){
                })
            }
            catch(e){
            }
        }

        getServicesData().then(function(data){
            setTimeout(() => {
                return auth.signInWithEmailAndPassword(email, password)
            }, 2000)
        })


    }

    function register(email, password){
        return createTemplateData(email, password)
        // create template data for new users (labour, parts, etc)

    }
    function login(email, password){
        return auth.signInWithEmailAndPassword(email, password)
    }

    function logOut(){
        return auth.signOut()
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setLoading(false)
            setCurrentUser(user)
            user ? secureLocalStorage.setItem("logged-in", true) : secureLocalStorage.setItem("logged-in", false)
        })
        return unsubscribe
    }, [])



    const value = {
        currentUser,
        login,
        register,
        logOut
    }
    return(
        <AuthContext.Provider value = { value }>
            {children}
        </AuthContext.Provider>
    )
}