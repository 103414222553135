import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button'
import Grid from "@mui/material/Grid";
import {useState, useEffect} from "react";
import Typography from "@mui/material/Typography";
import {doc, getDoc, setDoc} from "@firebase/firestore";
import {db} from "../authentification/firebase";
import {useAuth} from "../../contexts/AuthContext";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";

function AddProblem({problemsData, setProblemsData, addProblemLabel, helperText, problemsDict, setProblemsDict}) {

    const handleSaveProblems = async () => {
        problemsList &&
            problemsList.length !== 0 &&
                    await setDoc(doc(db, `${uid}lists`, 'problems'), {
                        problemsList,
                        toggleProblemsAutomaticAdd
                    })
    }


    const [toggleProblemsAutomaticAdd, setToggleProblemsAutomaticAdd] = useState(false);

    const [problemsList, setProblemsList] = useState()
    const currentUser = useAuth().currentUser
    const uid = currentUser.uid

    useEffect(() => {
        let docRef = null
        let docSnap = null

        const getProblemsData = async () => {
            try{
                docRef = await doc(db,  `${uid}lists`, 'problems')
                docSnap = await getDoc(docRef);
            }
            catch(e){
            }
        }

        getProblemsData().then(() => {
            try {
                let data = docSnap.data()
                setProblemsList(data.problemsList)
                setToggleProblemsAutomaticAdd(data.toggleProblemsAutomaticAdd)
            }

            catch(e) {

            }

            }
        )
    }, [])


    useEffect(() => {
        if (problemsList && problemsList.length !== 0)
            handleSaveProblems()
    }, [problemsList, toggleProblemsAutomaticAdd])


    const addNewProblemToOptionsList = () => {
        const newProblemsList = []
        let add = true

        if (problemsList && problemsList.length !== 0){
            problemsList.forEach((element) => {
                if (element.item === problemsData.newProblem)
                    add = false;
                newProblemsList.push({
                    item: element.item,
                })
            })
        }
        newProblemsList.push({
            item: problemsData.newProblem,
        })
        if (add && toggleProblemsAutomaticAdd) {
            setProblemsList(newProblemsList)
        }
    }

    const handleAddNewProblem = () => {
        if (problemsData.newProblem === '')
            ;
        else {
            const problems = problemsData.problems.slice(0)
            var adauga = true;
            for (var i=0; i<problems.length; i++)
                if (problems[i] === problemsData.newProblem)
                    adauga = false
            if (adauga) {
                problems.push(problemsData.newProblem)
                problemsDict.push({
                    key: problemsData.newProblem,
                    value: {
                        problems: [],
                        newProblem: ''
                    }
                }

                )
            }
            setProblemsData({...problemsData, problems: problems, newProblem: ''})
        }

    }
    return (
        <Grid container spacing={3} direction={'row'} justifyContent={'space-between'} alignItems={'center'} width = 'auto' marginBottom={'3vh'}>
            {/*<Grid item>*/}
            {/*    <Typography fontSize={'140%'}>*/}
            {/*        {helperText}*/}
            {/*    </Typography>*/}
            {/*</Grid>*/}
            <Grid item>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={toggleProblemsAutomaticAdd}
                                onChange={() => {
                                    setToggleProblemsAutomaticAdd(!toggleProblemsAutomaticAdd)
                                }}
                            />
                        }
                        label={'Salveaza defectiuni automat'}
                    />
                </FormGroup>
            </Grid>
            <Grid item>
                <Autocomplete
                    size={'small'}
                    freeSolo
                    options={ problemsList ? problemsList.map( e => e.item) : []}
                    sx={{width: 300}}
                    value = {problemsData.newProblem}
                    renderInput={(params) => <TextField{...params} label={addProblemLabel} onChange={
                        (event) => setProblemsData({
                            ...problemsData,
                            newProblem: event.target.value
                        })}
                    onSelect = {
                        (event) => setProblemsData({
                            ...problemsData,
                            newProblem: event.target.value
                        })}

                    />}
                />

            </Grid>

            <Grid item>

                <Button variant="outlined"
                        onClick={() => {
                            handleAddNewProblem()
                            addNewProblemToOptionsList()
                        }}

                >

                    Adaugă</Button>

            </Grid>
        </Grid>
    );
}


export default AddProblem