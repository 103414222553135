import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import ClientDetails from './ClientDetails';
import CarDetails from './CarDetails';
import ProblemsDescribedByClient from "./ProblemsDescribedByClient";
import ProblemsFoundByService from "./ProblemsFoundByService";
import {useEffect, useRef, useState} from "react";
import {db} from '../authentification/firebase'
import {addDoc, collection, getDocs} from '@firebase/firestore'
import { Link } from "react-router-dom";
import {useAuth} from "../../contexts/AuthContext";
import EstimateError from "../alerts/EstimateError";
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import Slide from "@mui/material/Slide";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from 'react-router-dom'
import Parts from "./Parts";
import VehicleReception from "./VehicleReception";
import ServiceRecommendations from "./ServiceRecommendations";
import Labour from "./Labour";
import RepairRequirements from "./RepairRequirements";



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(5),
        marginBottom: 0,

        paddingTop: 0,
        border: 0,
        '&.Mui-disabled': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));

const steps = ['client', 'car', 'problemsDescription', 'serviceRequirements', 'reception', 'repairRequirements', 'parts', 'labour', 'recommendations']

const stepsDict = {
    'client': 'Client',
    'car': 'Autovehicul',
    'problemsDescription': 'Descriere defecțiuni',
    'serviceRequirements': 'Cerințe de service',
    'reception': 'Recepție autovehicul',
    'repairRequirements': 'Constatari în service',
    'parts': 'Piese',
    'labour': 'Manopera',
    'recommendations': "Recomandări service",
}

const theme = createTheme();

export default function CarEstimate() {
    const currentUser = useAuth().currentUser
    const uid = currentUser.uid
    const deviz = collection(db, `${uid}`)

    const [clientData, setClientData] = useState({
        clientType: 'person',
        name: '',
        email: '',
        phone: '',
        address: '',
        company: '',
        CUI: '',
        commerceRegistrationNumber: '',
        bank: '',
        IBAN: '',
    });

    const [carData, setCarData] = useState({
        vin: '',
        plate: '',
        brand: '',
        model: '',
        year: '',
        type: '',
        capacity: '',
        motorCode: '',
        fuel: '',
        color: '',
        kw: '',
    });

    const [problemsData, setProblemsData] = useState({
        problems: [],
        newProblem: '',

    });

    const [foundProblemsData, setFoundProblemsData] = useState({
        problems: [],
        newProblem: '',

    });

    const [labourDiscount, setLabourDiscount] = useState(0)
    const [labourDiscountType, setLabourDiscountType] = useState('percentage')

    const [partsDiscount, setPartsDiscount] = useState(0)
    const [partsDiscountType, setPartsDiscountType] = useState('percentage')

    const [problemsDict, setProblemsDict] = useState([])

    const [partsData, setPartsData] = useState({
        parts: [],
        newPart: ''
    })

    const [receptionData, setReceptionData] = useState({
        info: [],
        newInfo: '',
        mileage: ''
    })

    const [recommendationsData, setRecommendationsData] = useState({
        recommendations: [],
        newRecommendation: ''
    })

    const [currentLabourTab, setCurrentLabourTab] = useState(0)
    const [currentReceptionTab, setCurrentReceptionTab] = useState(0)

    const [activeStep, setActiveStep] = React.useState(0);
    const [step, setStep] = React.useState('client');
    const [error, setError] = useState("")
    const [newError, setNewError] = useState(true)

    const [deletionError, setDeletionError] = useState("")
    const [newDeletionError, setNewDeletionError] = useState(false)

    const navigate = useNavigate();

    const [showCode, setShowCode] = useState(false)
    const [showMaker, setShowMaker] = useState(false)
    const [showInfo, setShowInfo] = useState(false)

    const [requirementsHourlyLabourData, setRequirementsHourlyLabourData] = useState([]);
    const [requirementsServicesData, setRequirementsServicesData] = useState([]);
    const [requirementsFinalVerificationsData, setRequirementsFinalVerificationsData] = useState([]);


    const [hourlyLabourData, setHourlyLabourData] = useState({
        items: [],
        options: [],
        newItem: ''
    })

    const [servicesData, setServicesData] = useState({
        items: [],
        options: [],
        newItem: ''
    })

    const [finalVerificationsData, setFinalVerificationsData] = useState({
        items: [],
        options: [],
        newItem: ''
    })

    const [mechanic, setMechanic] = useState({
        id: '',
        name: '',
        imageURL: ''
    })
    const mechanicsRef = collection(db, `${uid}mechanics`) // luam colectia de devize din firestore
    const [mechanics, setMechanics] = useState([])

    const getMechanics = async () => {
        const data = await getDocs(mechanicsRef).then()
        setMechanics(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
    }

    useEffect(() => {
        getMechanics()
    }, [])


    const [currentRepairRequirementsTab, setCurrentRepairRequirementsTab] = useState(0)

    function validateStep() {
        //const steps = ['client', 'car', 'problemsDescription', 'serviceRequirements', 'reception', 'repairRequirements', 'parts', 'labour', 'recommendations']
        switch (step) {
            case 'client':
                return clientData.name !== "" && clientData.phone !== ""
            case 'car':
                return carData.plate !== ""
            case 'problemsDescription':
                return true
            case 'serviceRequirements':
                return true
            case 'reception':
                return true
            case 'repairRequirements':
                return true
            case 'parts':
                return true
            case 'labour':
                return true
            case 'recommendations':
                return true
            default:
                throw new Error('Unknown step');
        }
    }

    const validateEstimate = () => {
        if ( clientData.name === "" || clientData.phone === "")
        {
            setError("Datele clientului sunt incomplete")
            setStep("client")
            setNewError(true)
            return false

        }
        else if (carData.plate === "")
        {
            setError("Datele autovehiculului sunt incomplete")
            setStep("car")
            setNewError(true)
            return false

        }
        return true
    }

    const getStepContent = (step) => {
        //const steps = ['client', 'car', 'problemsDescription', 'serviceRequirements', 'reception', 'repairRequirements', 'parts', 'labour', 'recommendations']

        switch (step) {
            case 'client':
                return <ClientDetails clientData = {clientData}  setClientData = {setClientData} error = {error}/>;
            case 'car':
                return <CarDetails carData = {carData} setCarData = {setCarData}  error = {error}/>;
            case 'problemsDescription':
                return <ProblemsDescribedByClient problemsData = {problemsData} setProblemsData = {setProblemsData} problemsDict={problemsDict} setProblemsDict={setProblemsDict}
                        otherProblemsData={foundProblemsData} deletionError = {deletionError} setDeletionError = {setDeletionError} newDeletionError = {newDeletionError}  setNewDeletionError = {setNewDeletionError}
                        />;
            case 'serviceRequirements':
                return <ProblemsFoundByService foundProblemsData = {foundProblemsData} setFoundProblemsData = {setFoundProblemsData} problemsDict={problemsDict} setProblemsDict={setProblemsDict}
                        otherProblemsData={foundProblemsData} deletionError = {deletionError} setDeletionError = {setDeletionError} newDeletionError = {newDeletionError}  setNewDeletionError = {setNewDeletionError}
                                               hourlyLabourData={hourlyLabourData} setHourlyLabourData={setHourlyLabourData}
                                               servicesData={servicesData} setServicesData={setServicesData}
                                               mechanics={mechanics}
                                               mechanic={mechanic} setMechanic={setMechanic}
                        />;
            case 'reception':
                return <VehicleReception receptionData={receptionData} setReceptionData={setReceptionData}
                        currentReceptionTab={currentReceptionTab}
                                         setCurrentReceptionTab={setCurrentReceptionTab}
                />
            case 'repairRequirements':
                return <RepairRequirements currentRepairRequirementsTab={currentRepairRequirementsTab} setCurrentRepairRequirementsTab={setCurrentRepairRequirementsTab}
                                           problemsData = {problemsData} setProblemsData = {setProblemsData}
                                           foundProblemsData={foundProblemsData} setFoundProblemsData={setFoundProblemsData}
                                           problemsDict = {problemsDict} setProblemsDict = {setProblemsDict}

                                           hourlyLabourData ={hourlyLabourData} setHourlyLabourData = {setHourlyLabourData}
                                           finalVerificationsData={finalVerificationsData} setFinalVerificationsData={setFinalVerificationsData}
                                           servicesData={servicesData} setServicesData={setServicesData}
                                           requirementsHourlyLabourData = {requirementsHourlyLabourData} setRequirementsHourlyLabourData={setRequirementsHourlyLabourData}
                                            requirementsServicesData={requirementsServicesData} setRequirementsServicesData={setRequirementsServicesData}
                                            requirementsFinalVerificationsData={requirementsFinalVerificationsData} setRequirementsFinalVerificationsData = {setRequirementsFinalVerificationsData}
                                           deletionError = {deletionError} setDeletionError = {setDeletionError} newDeletionError = {newDeletionError}  setNewDeletionError = {setNewDeletionError}

                />
            case 'parts':
                return <Parts partsData = {partsData} setPartsData={setPartsData} showCode={showCode} showMaker={showMaker} showInfo={showInfo}
                               setShowCode={setShowCode} setShowInfo={setShowInfo} setShowMaker={setShowMaker}
                                partsDiscount={partsDiscount} setPartsDiscount={setPartsDiscount} partsDiscountType={partsDiscountType} setPartsDiscountType={setPartsDiscountType}
                        />
            case 'labour':
                return <Labour currentLabourTab={currentLabourTab} setCurrentLabourTab={setCurrentLabourTab}
                               hourlyLabourData ={hourlyLabourData} setHourlyLabourData = {setHourlyLabourData}
                                finalVerificationsData={finalVerificationsData} setFinalVerificationsData={setFinalVerificationsData}
                               servicesData={servicesData} setServicesData={setServicesData}
                               labourDiscount={labourDiscount} setLabourDiscount = {setLabourDiscount}
                               labourDiscountType={labourDiscountType} setLabourDiscountType = {setLabourDiscountType}
                               requirementsHourlyLabourData = {requirementsHourlyLabourData} setRequirementsHourlyLabourData={setRequirementsHourlyLabourData}
                               requirementsServicesData={requirementsServicesData} setRequirementsServicesData={setRequirementsServicesData}
                               requirementsFinalVerificationsData={requirementsFinalVerificationsData} setRequirementsFinalVerificationsData = {setRequirementsFinalVerificationsData}
                               deletionError = {deletionError} setDeletionError = {setDeletionError} newDeletionError = {newDeletionError}  setNewDeletionError = {setNewDeletionError}

                />
            case 'recommendations':
                return <ServiceRecommendations recommendationsData={recommendationsData} setRecommendationsData={setRecommendationsData}/>
            default:
                throw new Error('Unknown step');
        }
    }


    const handleStep = (event, newStep) => {
        if (newStep) {
            setStep(newStep)
        }
    }

    const handleNext = () => {
        if (validateStep()){
            setActiveStep(activeStep + 1);
            setError('')
        }
        else
        {
            setError("Câmpurile marcate cu * nu pot fi goale")
            setNewError(true)
        }


    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleFinish = (e) => {

        if (!validateEstimate())
            return

        try{
            let date = new Date().getDate().toString() + '/' + (new Date().getMonth() + 1).toString() + '/' + new Date().getFullYear().toString()

            let data = {
                mechanic,
                clientData,
                carData,
                problemsData,
                foundProblemsData,
                labourDiscount,
                labourDiscountType,
                partsDiscount,
                partsDiscountType,
                problemsDict,
                partsData,
                receptionData,
                recommendationsData,
                currentLabourTab,
                currentReceptionTab,
                activeStep,
                step,
                error,
                newError,
                deletionError,
                newDeletionError,
                showCode,
                showMaker,
                showInfo,
                requirementsHourlyLabourData,
                requirementsServicesData,
                requirementsFinalVerificationsData,
                hourlyLabourData,
                servicesData,
                finalVerificationsData,
                currentRepairRequirementsTab,
                date: date
            }
            addDoc(deviz, data);
            navigate('/log-in', { replace: true });
        }
        catch(e){
        }



    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppBar
                position="absolute"
                color="default"
                elevation={0}
                sx={{
                    borderBottom: (t) => `1px solid ${t.palette.divider}`,
                }}
            >

            </AppBar>
            <Grid container spacing={2}
                  direction="column"
                  justifyContent="space-around  "
                  alignItems="center"
                  auto
                  padding={5}
                  style={{ gap: 30 }}
            >

                <Grid container spacing={6}
                  direction="row"
                  justifyContent="start"
                  alignItems="start"
                  lg
                >
                {/*navigation system*/}

                    <Grid item width={'30vw'}>
                        <Container display="flex" component="main">
                            <StyledToggleButtonGroup
                                size="small"
                                value={step}
                                exclusive
                                onChange={handleStep}
                                orientation="vertical"
                                fullWidth
                            >
                                {
                                    (carData.plate + carData.brand + carData.model !== '') &&
                                    <List style={{height: '5vh'}}>
                                        <ListItem >
                                            <ListItemText primary= {carData.plate} secondary={carData.brand + ' ' + carData.model}
                                                          primaryTypographyProps={{fontSize: '24px', fontWeight: '500'}}
                                                          secondaryTypographyProps={{fontSize: '18px', fontWeight: '400'}}
                                            />
                                        </ListItem>
                                    </List>
                                }


                                {steps.map((element) => {
                                    return <ToggleButton value={element} sx={{height: '7vh', justifyContent: 'start', alignItems: 'center', marginBottom: '99vh'}}>
                                        <Typography variant="h6" gutterBottom
                                                    sx={{justifyContent: "center", display: 'block', paddingTop: "2.0vh", textTransform: 'none'}}>
                                            {stepsDict[element]}
                                            {/*{element === 'car' && carData.plate}*/}
                                        </Typography>
                                    </ToggleButton>
                                })}
                            </StyledToggleButtonGroup>

                        </Container>
                    </Grid>

                {/*/navigation system*/}

                    {/*content*/}

                    <Grid item width={'60vw'} marginTop={"10vh"}>
                        {getStepContent(step)}
                    </Grid>

                    {/*content*/}


                </Grid>


            {/*set butoane cancel si save*/}
                <Grid container spacing={2}
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      lg
                >
                    <Grid item>
                        <Button variant="outlined"
                                onClick={handleFinish}
                                sx={{color: 'green', borderColor: 'green'}}
                        > Save </Button>
                    </Grid>
                        <Grid item>
                            <Button variant="outlined"
                                onClick={() =>{navigate('/log-in', { replace: true })}}
                                sx={{color: 'red', borderColor: 'red'}}

                        >Cancel
                            </Button>
                        </Grid>



                </Grid>
            </Grid>
            {error !== "" && <EstimateError error = {error}  refreshOn = {newError} setRefreshOn={setNewError}/>}
            {newDeletionError && <EstimateError error = {deletionError}  refreshOn = {newDeletionError} setRefreshOn={setNewDeletionError}/>}

        </ThemeProvider>

    );
}